<template>
    <div class="container-fluid mt-5 mb-5">

        <h3 class="text-center mb-5">PAGO</h3>

        <div class="row">

            <div v-if="!openEditarPago" class="col-2 pe-5">

            </div>



            <div class="col-lg-4 col-12">

                <div class="pago courier-prime-regular">
                    <div class="text-center">
                        <strong>{{ this.pago.nombreCliente }}</strong>
                        <p>{{ this.pago.dniCliente }}</p>
                    </div>


                    <div class="mb-2 mt-3 d-flex">
                        <div class="col-6"><strong>Fecha Pago:</strong></div>
                        <div class="col-6">{{ this.dateFormat(this.fechaPagado) }}</div>
                    </div>
                    <div class="mb-2 d-flex">
                        <div class="col-6"><strong>Monto Pagado:</strong></div>
                        <div class="col-6">{{ this.formatoNumero(this.montoPagado) }} lps</div>
                    </div>
                    <div class="mb-2 d-flex">
                        <div class="col-6"><strong>IFI:</strong></div>
                        <div class="col-6">Banco {{ this.nombreBanco }}</div>
                    </div>
                    <div class="mb-2 d-flex">
                        <div class="col-6"><strong>No cuenta :</strong></div>
                        <div class="col-6">{{ this.noCuenta }}</div>
                    </div>
                    <div class="mb-2 d-flex">
                        <div class="col-6"><strong>No.ref Recibo:</strong></div>
                        <div class="col-6">{{ this.noReferenciaRecibo }}</div>
                    </div>
                    <div v-if="this.esReadecuado" class="mb-2 d-flex mt-3 text-primary">
                        <div class="col-12"><strong>Es pago de refinanciamiento
                        </strong></div>
                        
                      
                    </div>


                    <div class="mt-3 row d-flex justify-content-end">
                        <div class="col-6 row d-flex justify-content-end">
                            <button @click="editarPago" class="btn btn-primary" style="color: white; width: 150px;">
                                Editar Pago </button>
                        </div>
                    </div>


                </div>

            </div>

            <div class="col-lg-4 col-12 ps-5 pe-5 mb-5">
                <img class="img-recibo" :src="imgPath + '/recibo/' + pago.slug + '.jpg'" alt="Recibo">
            </div>
            <div v-if="openEditarPago" class="col-lg-4 col-12  pe-5">

                <div class="d-flex justify-content-between mb-2">
                    <strong class="display-4">Editar Pago</strong>
                    <button @click="updatePago()" class="btn btn-dark"
                        style="width: auto; margin-top: 0;">Actualizar</button>
                </div>


                <div class="control mb-2">
                    <label class="pagos-text" for="fechaPagado"><strong>Fecha de Pago (recibo):</strong> </label>
                    <input v-model="this.fechaPagado" type="date" required class="form-control" name="fechaPagado"
                        id="fechaPagado" min="{{this.fechaUltimoPago}}">
                    <span v-if="this.fechaPagado < this.fechaUltimoPago?.slice(0, 10)" class="text-danger">La fecha no
                        puede
                        ser
                        menor a la
                        fecha del ultimo pago</span>
                </div>
                <div v-if="!this.Consultor" class="control mb-2">
                    <label class="pagos-text" for="montoPago"><strong>Monto a registrar (recibo):</strong> </label>
                    <input v-model="this.montoPagado" type="number" required class="form-control" name="montoPago"
                        id="montoPago" min="0">
                </div>


                <div v-if="!this.Consultor" class="control mb-2">
                    <label class="pagos-text" for="montoPago"><strong>Nombre del Banco:</strong> </label>
                    <select v-model="this.nombreBanco" type="number" required class="form-select"
                        name="noReferenciaRecibo" id="nombreBanco" min="0">

                        <option v-for="banco in bancos" :key="banco.nombreBanco" :value="banco.nombreBanco">
                            {{ banco.nombreBanco }}
                        </option>
                    </select>
                </div>
                <div class="control " v-if="this.nombreBanco !== '' && !this.Consultor">
                    <label class="pagos-text" for="montoPago"><strong> No de Cuenta:</strong> </label>
                    <select v-model="this.noCuenta" type="number" required class="form-select" name="noReferenciaRecibo"
                        id="noCuenta" min="0">

                        <option v-for="noBanco in numerosDeCuenta" :key="noBanco.nombreBanco" :value="noBanco">
                            {{ noBanco }}
                        </option>
                    </select>
                </div>
                <div v-if="!this.Consultor" class="control mb-2">
                    <br />
                    <label for="montoPago"><strong>No. Referencia Recibo:</strong> </label>
                    <input v-model="this.noReferenciaRecibo" type="number" required class="form-control"
                        name="noReferenciaRecibo" id="noReferenciaRecibo" min="0">
                </div>

                <div v-if="!this.Consultor" class="control mb-2">
                    <br />
                    <label for="montoPago"><strong>¿Es un pago de refinanciamiento?:</strong> </label>
                    <div class="form-check ms-5">
                        <input v-model="esReadecuado" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <label v-if="this.esReadecuado" class="form-check-label text-primary" for="flexCheckDefault">
                            Es pago de refinanciamiento
                        </label>
                        <label v-else class="form-check-label text-primary" for="flexCheckDefault">
                            No es pago de refinanciamiento
                        </label>
                    </div>
                </div>


                <div v-if="this.slug !== ''" class="mb-3">
                    <img :src="`${this.imgPath}/covers/${this.slug}.jpg`" class="img-fluid img-thumbnail book-cover"
                        alt="cover">
                </div>

                <!-- <div v-if="!this.Consultor" class="mb-3">
                    <label for="formFile" class="form-label"><strong>Imagen Recibo:</strong> </label>
                    <input ref="coverInput" class="form-control img-recibo" type="file" id="formFile" required
                        accept="image/jpeg, image/jpg, image/png" @change="loadCoverImage">
                    <div v-if="isLoading" class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>

                </div>

                <button v-if="!this.Consultor" class="btn btn-primary" style="color: white;" @click="pegarImagen">Pegar
                    imagen</button>
                <div v-if="imagenPegada">
                    <img class="imagen-recibo" :src="imagenPegada" alt="Imagen pegada">
                </div> -->

            </div>
            <div v-else class="col-2"></div>
        </div>



    </div>
</template>

<script>
import Swal from 'sweetalert2';
import Security from '../security';

export default {
    data() {
        return {
            imgPath: process.env.VUE_APP_IMAGE_URL,
            fechaPagado: "",
            fechaUltimoPago: "",
            Consultor: "",
            idCredito: 0,
            montoPagado: 0,
            nombreBanco: "",
            noCuenta: "",
            banco: [],
            esReadecuado: false,
            noReferenciaRecibo: "",
            slug: "",
            openEditarPago: false,
            bancos: [
                {
                    nombreBanco: 'Atlantida',
                    cuentas: [
                        '10120625735',
                        '10120625743',
                        '10120625750',
                        '10120625750',
                        '10111039284',
                        '10120625768'
                    ],
                },
                {
                    nombreBanco: 'Ficohsa',
                    cuentas: [
                        '200014395554',
                        '200014395619',
                        '200014395759',
                        '200014396542'
                    ]
                },
                {
                    nombreBanco: 'Banrural',
                    cuentas: [
                        '15110490970',
                        '15110491370',
                        '15110491384',
                        '15110491398',
                        '15110496114',
                        '15110496132',
                        '15110496146',
                        '15310166680',
                        '15310167484',
                    ]
                },
                {
                    nombreBanco: 'Crédito Solidario',
                    cuentas: [
                        '21-401-177888-4',
                        '02701010052409',
                    ]
                },

            ],
            pago: [],

        }
    },
    beforeMount() {
        const payload = {
            idPago: parseInt(this.$route.params.pagoId, 10)
        }

        console.log('Inicio', payload);
        fetch(process.env.VUE_APP_API_URL + "/admin/pagos/pagoById", Security.requestOptions(payload))
            .then((response) => response.json())
            .then((response) => {
                if (response.error) {
                    // this.$emit('error', response.message);

                    console.error(response)
                } else {
                    console.log('respuesta', response.data)
                    this.pago = response.data.pagos

                    this.idCredito = this.pago.idCredito
                    this.fechaPagado = this.convertirFechaISO(this.pago.fechaPagado)
                    this.montoPagado = this.pago.montoPagado
                    this.nombreBanco = this.pago.ifi
                    this.esReadecuado = this.pago.esReadecuado
                    this.noCuenta = this.pago.noCuenta
                    this.noReferenciaRecibo = this.pago.noReferenciaRecibo
                }
            })
            .catch(error => {
                this.$emit('error', error)
            })
    },
    methods: {
        updatePago() {
            const payload = {
                idPago: parseInt(this.pago.id),
                idCredito: parseInt(this.idCredito),
                fechaPagado: this.fechaPagado,
                montoPagado: this.montoPagado,
                idUsuario: Security.getUser()[0].user.id,
                noReferenciaRecibo: String(this.noReferenciaRecibo),
                esReadecuado: this.esReadecuado,
                noCuenta: this.noCuenta,
                ifi: this.nombreBanco,
            }
            console.log(payload)
            Swal.fire({
                title: '¿Estás Seguro que deseas Actualizar el pago?',
                icon: 'success',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmar!'
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log('confirmado')
                    // this.buscarCredito(this.busquedaInput);

                    fetch(
                        process.env.VUE_APP_API_URL +
                        "/admin/credito/actualizar-pago",
                        Security.requestOptions(payload)
                    )
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                Swal.fire({
                                    // position: 'top-end',
                                    icon: "error",
                                    title: data.message,
                                    showConfirmButton: false,
                                    timer: 1500,
                                });

                            } else {

                                console.log(data.data);
                                Swal.fire({
                                    // position: 'top-end',
                                    icon: "success",
                                    title: "Pago Actualizado Exitosamente!",
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                                // this.submitImage(data.slug)


                                // this.buscarCredito(this.busquedaInput)
                                // this.closeModalHacerPago()


                            }
                        });

                    // this.montoPagado = ''
                    // this.fechaPagado = ''

                }
            })
        },

        convertirFechaISO(fechaISO) {
            if (!fechaISO) return null; // Manejo de valores nulos o indefinidos
            return fechaISO.split("T")[0];
        },
        editarPago() {
            if (this.openEditarPago) {
                this.openEditarPago = false
            } else {
                this.openEditarPago = true
            }
        },
        formatoNumero(numero) {
            return new Intl.NumberFormat("en-US").format(numero)
        },
        dateFormat(param) {

            var fechaISO = param;

            // Crear un objeto Date con la fecha proporcionada
            var fecha = new Date(fechaISO);

            // Obtener el día del mes (agregando un cero inicial si es necesario)
            var dia = ('0' + fecha.getUTCDate()).slice(-2);

            // Obtener el mes (agregando un cero inicial si es necesario)
            var mes = ('0' + (fecha.getUTCMonth() + 1)).slice(-2);

            // Obtener el año
            var anio = fecha.getUTCFullYear();

            // Formatear la fecha según tus necesidades
            var fechaFormateada = dia + '/' + mes + '/' + anio;

            // console.log(fechaFormateada); // Salida: 01/12/2023

            return fechaFormateada;

        },
        async pegarImagen() {
            try {
                // Pegar la imagen desde el portapapeles
                const clipboardItems = await navigator.clipboard.read();
                for (const clipboardItem of clipboardItems) {
                    for (const type of clipboardItem.types) {
                        if (type.startsWith('image/')) {
                            const blob = await clipboardItem.getType(type);
                            this.imagenPegada = URL.createObjectURL(blob);
                            this.recibo = this.imagenPegada
                            return;
                        }
                    }
                }
                console.log('No se encontró ninguna imagen en el portapapeles.');
            } catch (error) {
                console.error('Error al pegar la imagen:', error);
            }
        },
    },
    watch: {
        nombreBanco: function (nombreBanco) {
            let numerosDeCuenta = this.bancos.find(banco => banco.nombreBanco === nombreBanco);

            console.log(numerosDeCuenta.cuentas)
            console.log(nombreBanco)

            console.log(numerosDeCuenta)
            this.numerosDeCuenta = numerosDeCuenta.cuentas;
        },
    }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.courier-prime-regular {
    font-family: "Courier Prime", monospace;
    font-weight: 400;
    font-style: normal;
}
</style>
<style>
.pago {
    background-color: rgb(244, 244, 244);
    width: 100%;
    padding: 35px;
    border-radius: 5px;
}

.img-recibo {
    width: 100%;
    border-radius: 5px;
}
</style>