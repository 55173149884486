<template>
    <div class="container p-5">
        <div class=" d-flex justify-content-between">
            <div v-if="this.pagoOption" class="w-50 ">
                <h3 class="mb-3">Pagos por rango de fecha </h3>
                <VueDatePicker v-model="date" :range="true" rangeSeparator=" to "></VueDatePicker>
            </div>
            <div v-else>
                <h3 class="mb-3">Refinanciamientos </h3>

            </div>



            <div>
                <div class="input-group mt-4">
                    <button @click="selectOption(1)" class="btn btn-outline-secondary "
                        :class="(this.pagoOption) ? 'active' : ''" type="button">Pagos</button>
                    <button @click="selectOption(2)" class="btn btn-outline-secondary"
                        :class="(!this.pagoOption) ? 'active' : ''" type="button">Refinanciamientos</button>
                </div>
            </div>
        </div>

        <!-- {{ this.date[0] }} -->


        <TablaPagos  :key="`${date[0]}-${date[1]}-${pagoOption}`" :startDate="this.date[0]" :endDate="this.date[1]" :option="this.pagoOption" />
        <!-- <div v-else>Trabando en los refinanciamientos 🤖</div> -->

    </div>

</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import TablaPagos from './Tabla-Pagos.vue';

export default {
    components: {
        VueDatePicker,
        TablaPagos
    },
    data() {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Establece las horas, minutos, segundos y milisegundos en 0
        return {
            date: [today, today], // Inicializa la fecha de hoy como la primera fecha del rango
            ready: false,
            pagos: [],
            pagoOption: true,
        };

    },
    methods: {
        selectOption(option) {
            if (option == 2) {
                this.pagoOption = false
            }
            if (option == 1) {
                this.pagoOption = true
            }

        }
    }

}
</script>

<style scoped>
.btn-check:checked+.btn-outline-secondary,
.btn-check:active+.btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
    color: #fff;

}
</style>
