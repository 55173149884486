<template>
    <div class="container-fluid pt-5">
        <h4 class="text-center display-4">Recordatorios de pago</h4>
        <div class="d-flex align-items-center justify-content-center">
            <h4 class="text-center display-5">Beneficiarios a los que su pago corresponde al día </h4>
            <h4 v-if="this.formatearFecha(this.date) === this.formatearFecha(new Date())" class="text-center display-5">&nbsp;de <b>hoy</b></h4>
            <h4 v-else class="text-center display-5">&nbsp;<b>{{ this.dateFormat(this.date) }} </b></h4>
        </div>
        <!-- <h4 v-if="(this.date.toDateString() === new Date().toDateString())" class="text-center display-4">Hoy</h4> -->
        <!-- {{ this.date }} === {{ new Date() }} : {{ this.date.toDateString() === new Date().toDateString() }} -->
        <div class="row flex-column d-flex justify-content-center align-items-center">
            <input class="w-25" v-model="date" type="date">
            <!-- -->
        </div>

        <div class="row d-flex justify-content-center mt-3">
            <div class="col-2">
                <p class="text-center">Mostrar {{ this.cantidadMostrar }} Créditos</p>
                <select v-model="cantidadMostrar" class="form-select" aria-label="Default select example">
                    <option selected value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                </select>
            </div>
            <div class="col-2">
                <p class="text-center">Sucursal</p>
                <select v-model="sucursal" class="form-select" aria-label="Default select example">
                    <option selected value="MOSTRAR TODAS">MOSTRAR TODAS</option>
                    <option v-for="(sucursal, index) in sucursales" :key="index" :value="sucursal">
                        {{ sucursal }}
                    </option>
                </select>
            </div>
        </div>

        <div v-if="ready" class="recordatorio-container">

            <ul>

                <div class="row " v-for="(credito, index) in creditos.slice(desde, hasta)" :key="index">

                    <div @click="irCredito(credito.cliente.dni)" class="recordatorio col-12">
                        <div class="row d-flex align-items-center">
                            <div class="col-4">
                                <p style="color: #0084C9;">PAGO PENDIENTE</p> {{ credito.cliente.nombres }} {{
                                    credito.cliente.apellidos }}
                            </div>
                            <div class="col-2">
                                <strong>Dni:</strong>
                                <p>{{ credito.cliente.dni }}</p>
                            </div>
                            <div class="col-2">
                                <strong>Sucursal:</strong>
                                <p>{{ credito.solicitud.sucursal }}</p>
                            </div>
                            <div class="col-2">
                                <strong>Producto:</strong>
                                <p>{{ credito.solicitud.producto }}</p>
                            </div>
                            <div class="col-2">
                                <strong>Teléfono: {{ credito.cliente.telefono }}</strong>
                                <!-- <strong>Teléfono 2: {{ credito.cliente.celular }} </strong> -->
                            </div>
                        </div>



                    </div>
                    <!-- <div class="col-2 d-flex align-items-center">
                        <button class="btn btn-success "><i class="fa-brands fa-whatsapp"></i></button>
                        <button class="btn btn-primary"><i class="fa-solid fa-message"></i></button>
                    </div> -->


                    <hr>
                </div>
            </ul>
        </div>
        <div v-else>
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-success" role="status" style="width: 3rem; height: 3rem;">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>

        <div class="d-flex align-items-center flex-column">
            <p>Mostrando de {{ this.desde + 1 }} hasta {{ this.hasta }} de {{ this.creditos.length }} créditos</p>
            <nav aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item">
                        <a class="page-link" @click="volver()" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li class="page-item" id="option-1"><a class="page-link" @click="opcion(1)">1</a></li>
                    <li v-if="noPaginas >= 2" class="page-item" id="option-2"><a class="page-link"
                            @click="opcion(2)">2</a></li>
                    <li v-if="noPaginas >= 3" class="page-item" id="option-3"><a class="page-link"
                            @click="opcion(3)">3</a></li>


                    <!-- <li v-for="n in ((noPaginas>3)?3:noPaginas)" :key="n" :id="'option-' + n" class="page-item">
                        <a class="page-link" @click="opcion(n)">{{ n }}</a>
                    </li> -->

                    <li v-if="noPagina >= 3 && (noPagina < noPaginas)" class="ms-2 me-2"> ... </li>
                    <li v-if="noPagina === 3" class="page-item" :id="`option-${this.noPagina + 1}`"><a class="page-link"
                            @click="opcion(this.noPagina + 1)">{{ this.noPagina + 1 }}</a></li>
                    <li v-if="noPagina > 3 && (noPagina < noPaginas)" class="page-item active"
                        :id="`option-${this.noPagina}`"><a class="page-link" @click="opcion(this.noPagina)">{{
                            this.noPagina }}</a></li>
                    <!-- <li v-if="noPagina >= 3 && (noPagina < noPaginas)" class="ms-2 me-2"> ... </li> -->
                    <li v-if="noPaginas > 3" class="ms-2 me-2"> ... </li>

                    <li v-if="noPaginas > 3" class="page-item" :id="`option-${this.noPaginas}`"><a class="page-link"
                            @click="opcion(this.noPaginas)">{{ this.noPaginas }}</a></li>
                    <li class="page-item">

                        <a class="page-link" @click="Proximo()" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>

    </div>





</template>

<script>
import Swal from 'sweetalert2';
import Security from './security';




export default {
    components: {
        // VueDatePicker,
    },
    data() {

        return {
            date: new Date(),
            ready: false,
            creditos: [],
            CreditosCompletos: [],
            sucursal: 'MOSTRAR TODAS',
            sucursales: [],


            desde: 0,
            hasta: 0,
            noPaginas: 0,
            cantidadMostrar: 5,
            noPagina: 1,
        };

    },
    beforeMount() {
        const fechaHoy = new Date();
        fechaHoy.setUTCHours(0, 0, 0, 0);
        // Guardamos las sucursales por usuario
        this.sucursalesRoles = Security.getUserSucursal()

        this.recordatorioPagoFetch(fechaHoy)

        if (localStorage.getItem('cantidad_mostrar') != 0) {
            this.cantidadMostrar = localStorage.getItem('cantidad_mostrar');
        }
        if (!localStorage.getItem('cantidad_mostrar')) {
            this.cantidadMostrar = 5;
        }
        if (localStorage.getItem('sucursal_recordatorio') != "") {
            this.sucursal = localStorage.getItem('sucursal_recordatorio');
        }
        if (!localStorage.getItem('sucursal_recordatorio')) {
            this.sucursal = 'MOSTRAR TODAS';
        }







    },
    methods: {
        recordatorioPagoFetch(fecha) {
            this.ready = false
            const payload = {
                fechaRecordatorio: fecha,
            }
            fetch(process.env.VUE_APP_API_URL + "/admin/recordatorios/pago", Security.requestOptions(payload))
                .then((response) => response.json())
                .then((response) => {
                    if (response.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.message,
                        });
                    } else {

                        this.creditos = response.data
                        this.CreditosCompletos = response.data

                        this.noPaginas = Math.ceil(this.creditos.length / this.cantidadMostrar)
                        this.botonActive(this.noPagina)
                        this.sucursales = this.obtenerSucursales(this.creditos)
                        this.mostrarSucursal(this.sucursal)

                        this.ready = true
                    }
                })
                .catch(error => {
                    this.$emit('error', error)
                })
        },
        volver() {
            if (this.desde > 0) {
                // if (condition) {
                document.getElementById(`option-${this.noPagina}`).classList.remove('active')
                if (document.getElementById(`option-${this.noPagina - 1}`)) {
                    this.botonActive(this.noPagina - 1)

                } else if (this.noPagina === this.noPaginas) {
                    document.getElementById(`option-${this.noPagina}`).classList.remove('active')
                    this.noPagina--

                } else {
                    this.botonActive(this.noPagina)
                    this.noPagina--
                }

                this.desde -= parseInt(this.cantidadMostrar, 10)
                this.hasta -= parseInt(this.cantidadMostrar, 10)

            }

        },
        Proximo() {
            if (this.noPaginas && (this.noPagina + 1 <= this.noPaginas)) {
                this.desde += parseInt(this.cantidadMostrar, 10)
                this.hasta += parseInt(this.cantidadMostrar, 10)

                if ((this.noPagina + 1) === 4) {
                    document.getElementById(`option-${this.noPagina}`).classList.remove('active')
                }
                if ((this.noPagina + 1) === this.noPaginas) {
                    this.botonActive(this.noPagina + 1)
                } else if ((this.noPagina < 3) && (this.noPagina < this.noPaginas)) {
                    this.botonActive(this.noPagina + 1)
                } else {
                    this.noPagina++
                }

            }

        },
        opcion(pagina) {

            this.desde = (pagina - 1) * parseInt(this.cantidadMostrar, 10)
            this.hasta = pagina * parseInt(this.cantidadMostrar, 10)
            document.getElementById(`option-${this.noPagina}`).classList.remove('active')

            this.botonActive(pagina)
        },
        botonActive(pagina) {

            if (this.noPagina > 0) {
                document.getElementById(`option-${this.noPagina}`).classList.remove('active')
            }

            document.getElementById(`option-${pagina}`).classList.add('active')
            this.noPagina = pagina
        },
        formatearFecha(fecha) {
            // Verificar si la fecha ya está en el formato 'YYYY-MM-DD'
            if (typeof fecha === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(fecha)) {
                return fecha;
            }

            // Si no está en el formato, convertir la fecha
            if (fecha instanceof Date) {
                const año = fecha.getFullYear();
                const mes = String(fecha.getMonth() + 1).padStart(2, '0');
                const dia = String(fecha.getDate()).padStart(2, '0');

                return `${año}-${mes}-${dia}`;
            }

            // Retornar null si el formato no es válido o la entrada no es una fecha válida
            return null;
        },
        
        irCredito(dni) {
            this.guardarEnLocalStorage('busqueda', dni);
            window.location.href = '/cartera';
        },
        guardarEnLocalStorage(clave, arrayDeObjetos, tamañoMaximo = 5 * 1024 * 1024) { // 5MB por defecto
            // Convertimos el array de objetos a una cadena JSON
            const jsonString = JSON.stringify(arrayDeObjetos);

            // Calculamos el tamaño del JSON en bytes
            const tamañoJSON = new Blob([jsonString]).size;

            // Verificamos si el tamaño es menor al límite permitido
            if (tamañoJSON <= tamañoMaximo) {
                // Si el tamaño es adecuado, lo guardamos en el localStorage
                localStorage.setItem(clave, jsonString);
                console.log(`Guardado correctamente en localStorage. Tamaño: ${tamañoJSON} bytes`);
            } else {
                console.error(`Error: El array excede el tamaño máximo permitido de ${tamañoMaximo} bytes.`);
            }
        },
        obtenerSucursales(creditos) {
            const todasLasSucursales = creditos
                .map(credito => credito.solicitud.sucursal.toUpperCase().trim()) // Convierte a mayúsculas y quita espacios
                .filter((sucursal, index, array) => array.indexOf(sucursal) === index); // Filtra duplicados
            // Verifica si "ALL" está en this.sucursalesRoles
            if (this.sucursalesRoles[0].includes('ALL')) {
                return todasLasSucursales;
            }
            // Filtra las sucursales basándose en this.sucursalesRoles
            const sucursalesFiltradas = todasLasSucursales.filter(sucursal =>
                this.sucursalesRoles[0].includes(sucursal)
            );

            return sucursalesFiltradas;

        },
        dateFormat(param) {
            // const date = new Date(param)

            // const dia = (param)?.slice(8, 10)
            // const mes = date.toLocaleDateString('es-ES', { month: 'long' })
            // const anio = date.getFullYear()
            // const dateFormated = `${dia}/${mes.slice(0, 3)}/${anio}`
            // return dateFormated
            // Fecha en formato ISO 8601
            // Fecha en formato ISO 8601
            var fechaISO = param;


            // Crear un objeto Date con la fecha proporcionada
            var fecha = new Date(fechaISO);

            // Obtener el día del mes (agregando un cero inicial si es necesario)
            var dia = ('0' + fecha.getUTCDate()).slice(-2);

            // Obtener el mes (agregando un cero inicial si es necesario)
            var mes = ('0' + (fecha.getUTCMonth() + 1)).slice(-2);

            // Obtener el año
            var anio = fecha.getUTCFullYear();

            // Formatear la fecha según tus necesidades
            var fechaFormateada = dia + '/' + mes + '/' + anio;


            return fechaFormateada;

        },
        mostrarSucursal(sucursal) {
            // if (this.CreditosCompletos.length === 0) {
            //     this.CreditosCompletos = this.creditos
            // }

            // if (sucursal === 'MOSTRAR TODAS') {
            //     this.creditos = this.CreditosCompletos
            // } else {
            //     this.creditos = this.CreditosCompletos.filter(credito =>
            //         credito.solicitud && credito.solicitud.sucursal === sucursal)

            // }

            if (this.CreditosCompletos.length === 0) {
                this.CreditosCompletos = this.creditos;
            }

            if (sucursal === 'MOSTRAR TODAS') {
                if (this.sucursalesRoles[0].includes('ALL')) {
                    // Mostrar todos los créditos si 'ALL' está en sucursalRoles
                    this.creditos = this.CreditosCompletos;
                } else {
                    // Filtrar los créditos según las sucursales en sucursalRoles
                    this.creditos = this.CreditosCompletos.filter(credito =>
                        (credito.solicitud && this.sucursalesRoles[0].includes(credito.solicitud.sucursal))
                    );
                }
            } else {
                // Filtrar por una sucursal específica
                this.creditos = this.CreditosCompletos.filter(credito =>
                    credito.solicitud && credito.solicitud.sucursal === sucursal
                );
            }

            localStorage.setItem('sucursal_recordatorio', sucursal);


            // Calcular el numero de paginas
            this.noPaginas = Math.ceil(this.creditos.length / this.cantidadMostrar)


            // Enviar a pagina 1
            document.getElementById(`option-${this.noPagina}`).classList.remove('active')
            this.noPagina = 1
            this.botonActive(this.noPagina)
            this.opcion(1)
        }
    },
    watch: {
        date(FechaNueva) {
            this.recordatorioPagoFetch(new Date(FechaNueva))
        },
        cantidadMostrar(cantidadNueva, cantidadVieja) {
            this.desde = 0
            if (cantidadNueva) {
                localStorage.setItem('cantidad_mostrar', cantidadNueva);
                this.hasta = parseInt(cantidadNueva, 10);

            } else {
                localStorage.setItem('cantidad_mostrar', cantidadVieja);

                this.hasta = parseInt(cantidadVieja, 10)
            }
            this.noPaginas = Math.ceil(this.creditos.length / this.cantidadMostrar)

            document.getElementById(`option-${this.noPagina}`).classList.remove('active')
            this.noPagina = 1
            this.botonActive(this.noPagina)
        },
        sucursal(sucursal) {
            this.mostrarSucursal(sucursal)

        }
    }

}
</script>

<style scoped>
.recordatorio-container {
    margin-top: 25px;
    /* padding: 20px; */
    border: 0.5px solid rgb(202, 202, 202);
    /* Cambia el color, el grosor y el estilo según lo desees */
    border-radius: 10px;
    /* Opcional: da esquinas redondeadas */
    padding-top: 10px;
}

.recordatorio {
    padding: 10px;
    cursor: pointer;
    margin-left: 0px;
}

.recordatorio:hover {
    background-color: rgb(235, 235, 235);
    /* width: 99%; */
    margin-left: 0px;
}

.recordatorio:active {
    background-color: rgb(220, 220, 220);
    /* width: 99%; */
}

.page-link {
    cursor: pointer;
}

hr {
    margin: 0;
}

.btn {
    margin: 0;
    color: white;
}
</style>