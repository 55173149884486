<template>
    <div ref="menuContainer" class="options-menu">
      <button class="options-button" @click="toggleMenu">
        <span class="options-icon"><i class="fa-solid fa-ellipsis"></i></span>
      </button>
      <div v-if="isOpen" class="menu-content" @click.stop>
        <button v-if="this.onCreate" @click="executeAction('create')">Crear</button>
        <button @click="executeAction('read')">Detalles</button>
        <button v-if="this.onUpdate" @click="executeAction('update')">Actualizar</button>
        <button @click="executeAction('delete')">Eliminar</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "OptionsMenu",
    props: {
      onCreate: Function,
      onRead: Function,
      onUpdate: Function,
      onDelete: Function,
    },
    data() {
      return {
        isOpen: false,
      };
    },
    methods: {
      toggleMenu() {
        this.isOpen = !this.isOpen;
      },
      closeMenu() {
        this.isOpen = false;
      },
      executeAction(action) {
        const actions = {
          create: this.onCreate,
          read: this.onRead,
          update: this.onUpdate,
          delete: this.onDelete,
        };
        if (actions[action]) actions[action]();
        this.closeMenu();
      },
      handleClickOutside(event) {
        if (this.isOpen && !this.$refs.menuContainer.contains(event.target)) {
          this.closeMenu();
        }
      },
    },
    mounted() {
      document.addEventListener("click", this.handleClickOutside);
    },
    beforeUnmount() {
      document.removeEventListener("click", this.handleClickOutside);
    },
  };
  </script>
  
  <style scoped>
  .options-menu {
    position: relative;
    display: inline-block;
  }
  
  .options-button {
    background-color: transparent;
    border: none;
    color: #333;
    margin-top: 0px;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
  }
  
  .options-button:hover {
    background-color: #f0f0f0;
  }
  
  .menu-content {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 5px;
    z-index: 1000;
    min-width: 150px;
    display: flex;
    flex-direction: column;
  }
  
  .menu-content button {
    background-color: transparent;
    border: none;
    padding: 10px 15px;
    text-align: left;
    cursor: pointer;
    font-size: 16px;
    color: #333;
    transition: background-color 0.2s ease;
    width: 100%;
  }
  
  .menu-content button:hover {
    background-color: #f0f0f0;
  }
  
  .options-icon {
    /* height: 100%; */
    margin-top: 2px;
    font-size: 18px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  </style>
  