<template>

    <div class="container mt-5">
        <div class="row">
            <div class="col-4"></div>
            <div class="col-4">            
                <h2 class=" display-3 text-center edu-au-vic-wa-nt-pre-unique-style bounceInLeft" 
                style="color: #303030">Solicitudes</h2>
                <h3 v-if="this.filtradoMostrar=='Aprobado'" class=" display-4 text-center mb-4 edu-au-vic-wa-nt-pre-unique-style bounceInRight" 
                style="color: #00A632">Aprobadas</h3>
                <h3 v-else-if="this.filtradoMostrar=='Desembolsado'" class=" display-4 text-center mb-4 edu-au-vic-wa-nt-pre-unique-style bounceInLeft" 
                style="color: #0084C9">Desembolsadas</h3>
                <h3 v-else class=" display-4 text-center mb-4 edu-au-vic-wa-nt-pre-unique-style bounceInRight" 
                style="color: #e0e0e0">Todas</h3>
            </div>
            <div class="col-4 d-flex align-items-end flex-column justify-content-center">
                <p class="edu-au-vic-wa-nt-pre-unique-style" style="font-size: 17px;">Módulo Desembolso</p>
                <spam class="edu-au-vic-wa-nt-pre-unique-style" style=" font-size: 14px;">  Contabilidad</spam>
            </div>
        </div>
        <br>
        <div class=" mb-1  d-flex align-items-md-end gap-5 justify-content-center">
            <div class="w-25 text-center">
                <label for="estadoSolicitud" class="text-center">Mostrar:</label>
                <select v-model="filtradoMostrar" required class=" form-select p-2 flex-grow-1" name="estadoSolicitud"
                    id="estadoSolicitud" placeholder="Estado">
                    <option value="Aprobado">Aprobadas</option>
                    <option value="Desembolsado">Desembolsadas</option>
                    <option value="Todos">Todas</option>
                </select>
            </div>
           
        </div>
        <TablaSolicitudes :key="`${filtradoMostrar}`" :mostrar="this.filtradoMostrar"/>
        
    </div>
</template>

<script>
import TablaSolicitudes from './TablaSolicitudes.vue';

export default {
    name: 'MostrarSolicitudes',
    data() {
        return {
            solicitudes: [],
            data: [],
            dateFormated: '',
            ready: false,
            filtradoMostrar: 'Aprobado'
        }
    },
    components:{
        TablaSolicitudes
    },


    methods: {
   
        formatearFecha(fechaOriginal) {
            // Función para formatear solo la fecha en el nuevo formato
            const fecha = new Date(fechaOriginal);
            const opciones = { year: 'numeric', month: '2-digit', day: '2-digit' };
            return fecha.toLocaleDateString('es-ES', opciones);
        },
        dateFormat(param) {
            const date = new Date(param)

            const dia = (param)?.slice(8, 10)
            const mes = date.toLocaleDateString('es-ES', { month: 'long' })
            const anio = date.getFullYear()
            const dateFormated = `${dia}/${mes.slice(0, 3)}/${anio}`
            return dateFormated
        },

    }

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Edu+AU+VIC+WA+NT+Pre:wght@400..700&display=swap');




</style>

<style scoped>
.edu-au-vic-wa-nt-pre-unique-style {
  font-family: "Edu AU VIC WA NT Pre", cursive;
  font-optical-sizing: auto;
  font-weight: 500; /* Cambia el valor según lo que necesites */
  font-style: normal;
 
}



.horizontal-scrollable>.row {
    overflow-x: auto;
    white-space: nowrap;
}

.horizontal-scrollable>.row>.col-xs-4 {
    display: inline-block;
    float: none;
}

/* Decorations */

.col-xs-4 {
    color: white;
    font-size: 24px;
    padding-bottom: 20px;
    padding-top: 18px;
}

.col-xs-4:nth-child(2n+1) {
    background: green;
}

.col-xs-4:nth-child(2n+2) {
    background: black;
}

.btn-am {
    height: 4px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0px;
}

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  }
  @-webkit-keyframes bounceInLeft {
  0%, 60%, 75%, 90%, 100% {
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
  opacity: 0;
  -webkit-transform: translate3d(-3000px, 0, 0);
  transform: translate3d(-3000px, 0, 0);
  }
  60% {
  opacity: 1;
  -webkit-transform: translate3d(25px, 0, 0);
  transform: translate3d(25px, 0, 0);
  }
  75% {
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
  }
  90% {
  -webkit-transform: translate3d(5px, 0, 0);
  transform: translate3d(5px, 0, 0);
  }
  100% {
  -webkit-transform: none;
  transform: none;
  }
  }
  @keyframes bounceInLeft {
  0%, 60%, 75%, 90%, 100% {
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
  opacity: 0;
  -webkit-transform: translate3d(-3000px, 0, 0);
  transform: translate3d(-3000px, 0, 0);
  }
  60% {
  opacity: 1;
  -webkit-transform: translate3d(25px, 0, 0);
  transform: translate3d(25px, 0, 0);
  }
  75% {
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
  }
  90% {
  -webkit-transform: translate3d(5px, 0, 0);
  transform: translate3d(5px, 0, 0);
  }
  100% {
  -webkit-transform: none;
  transform: none;
  }
  } 

  .bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  }
  @-webkit-keyframes bounceInRight {
  0%, 60%, 75%, 90%, 100% {
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
  opacity: 0;
  -webkit-transform: translate3d(3000px, 0, 0);
  transform: translate3d(3000px, 0, 0);
  }
  60% {
  opacity: 1;
  -webkit-transform: translate3d(-25px, 0, 0);
  transform: translate3d(-25px, 0, 0);
  }
  75% {
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
  }
  90% {
  -webkit-transform: translate3d(-5px, 0, 0);
  transform: translate3d(-5px, 0, 0);
  }
  100% {
  -webkit-transform: none;
  transform: none;
  }
  }
  @keyframes bounceInRight {
  0%, 60%, 75%, 90%, 100% {
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
  opacity: 0;
  -webkit-transform: translate3d(3000px, 0, 0);
  transform: translate3d(3000px, 0, 0);
  }
  60% {
  opacity: 1;
  -webkit-transform: translate3d(-25px, 0, 0);
  transform: translate3d(-25px, 0, 0);
  }
  75% {
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
  }
  90% {
  -webkit-transform: translate3d(-5px, 0, 0);
  transform: translate3d(-5px, 0, 0);
  }
  100% {
  -webkit-transform: none;
  transform: none;
  }
  } 
</style>