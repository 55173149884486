<template>
    <div class="container-fluid">
        <div v-if="ready" class="row text-center mt-3 horizontal-scrollable">
            <table class="table table-striped table-hover" id="myTable">
                <thead>
                    <tr>
                        <th class="text-center">ID</th>
                        <th class="text-center">Solicitante</th>
                        <th class="text-center">Dni</th>
                        <th class="">Monto</th>
                        <th>Sucursal</th>
                        <th class="text-center">Estado</th>
                        <th class="text-center">Acciones</th>

                    </tr>

                </thead>
                <tbody v-if="this.ready" id="tablaMostrar">
                    <tr v-for="(solicitud, index) in this.solicitudes" :key="solicitud.id">
                        <td class="">{{ index + 1 }}</td>
                        <td class="">{{ solicitud?.clienteNombres }}</td>
                        <td class="">{{ solicitud?.clienteDni }}</td>
                        <td class="">{{  this.formatoNumero(solicitud?.monto) }} {{ this.moneda }}</td>
                        <td>{{ solicitud.sucursal }}</td>
                        <td v-if="solicitud?.estado === 'Pendiente'" class=" text-warning">{{
                            solicitud?.estado
                        }}
                        </td>
                        <td v-else-if="solicitud?.estado === 'Aprobado'" class=" text-success">{{
                            solicitud?.estado }}</td>
                        <td v-else-if="solicitud?.estado === 'Rechazado'" class=" text-danger">{{
                            solicitud?.estado }}</td>
                        <td v-else-if="solicitud?.estado === 'Desembolsado'" class=" text-primary`"
                            style="color: #248AFD;">{{
                                solicitud?.estado }}</td>
                        <td v-else class=" text-dark">{{
                            solicitud?.estado }}</td>

                        <td class="">
                            <a :href="`/solicitud-aceptada/${solicitud.id}`" class="btn btn-am btn-inverse-primary">Ver
                                más</a>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <div v-else>
            <div class="row d-flex justify-content-center mt-5">
                <div v-if="this.mostrar == 'Aprobado'" class="spinner-border text-success" role="status"
                    style="width: 3rem; height: 3rem;">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <div v-else-if="this.mostrar == 'Desembolsado'" class="spinner-border text-primary" role="status"
                    style="width: 3rem; height: 3rem;">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <div v-else class="spinner-border text-black" role="status" style="width: 3rem; height: 3rem;">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Security from '@/components/security';
import DataTable from 'datatables.net-dt';
import Swal from 'sweetalert2';
import ExcelJS from "exceljs";

export default {
    data() {
        return {
            data: [],
            solicitudes: [],
            ready: false,
            moneda: 'lps',
        }
    },
    props: {
        mostrar: String,
    },
    beforeMount() {
        this.fetchData()

    },
    mounted() {
        if (this.mostrar == "") {
            this.initializeDataTable();
        }



    },

    updated() {
        this.initializeDataTable();
    },
    methods: {
        initializeDataTable() {
            console.log('Despues Iniciar');
            const myTable = document.querySelector("#myTable");
            if (myTable) {
                new DataTable(myTable);
            }
        },
        fetchData() {
            // Realiza la solicitud Fetch para obtener los datos
            console.log('Inicio');
            const payload = {
                filtroEstado: this.mostrar
            }
            fetch(process.env.VUE_APP_API_URL + "/admin/solicitudes/finanzas", Security.requestOptions(payload))
                .then((response) => response.json())
                .then((response) => {
                    if (response.error) {
                        Swal.fire({
                            icon: "error",
                            title: response.error,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    } else {
                        // Aqui va la logica aprobada
                        this.solicitudes = response.data.solicitudes
                        console.log("Desde componente Hijo", this.solicitudes);
                        this.ready = true
                    }
                })
                .catch(error => {
                    this.$emit('error', error)
                })
        },
        handleImprimir() {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("Data");
            worksheet.columns = [
                { header: "Nombre Beneficiario", key: "clienteNombres", width: 30 },
                { header: "ID", key: "clienteId", width: 20 },
                { header: "Monto", key: "monto", width: 10 },
                { header: "Cuenta Bancaria", key: "", width: 20 },
                { header: "Frecuencia Pago", key: "frecuenciaPago", width: 20 },
                { header: "Plazo", key: "plazoPago", width: 10 },
                { header: "Fecha de Caducidad", key: "", width: 20 },
                { header: "Estado", key: "estado", width: 20 },
            ];
            worksheet.getRow(1).font = { bold: true };
            worksheet.addRows(this.solicitudes);
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], {
                    type:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "solicitudes.xlsx");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
        },
        formatoNumero(numero) {
            return new Intl.NumberFormat("en-US").format(numero)
        },
        dateFormat(param) {

            var fechaISO = param;

            // Crear un objeto Date con la fecha proporcionada
            var fecha = new Date(fechaISO);

            // Obtener el día del mes (agregando un cero inicial si es necesario)
            var dia = ('0' + fecha.getUTCDate()).slice(-2);

            // Obtener el mes (agregando un cero inicial si es necesario)
            var mes = ('0' + (fecha.getUTCMonth() + 1)).slice(-2);

            // Obtener el año
            var anio = fecha.getUTCFullYear();

            // Formatear la fecha según tus necesidades
            var fechaFormateada = dia + '/' + mes + '/' + anio;

            console.log(fechaFormateada); // Salida: 01/12/2023

            return fechaFormateada;

        },
    }
}
</script>

<style></style>