<template>
    <div class="modal-body">
        <div class="">
            <label for="estadoSolicitud">Estado de la Solicitud {{ this.idSolicitud }}:</label>
            <select v-model="estadoSolicitud" required class="form-select" name="estadoSolicitud" id="estadoSolicitud">
                <option value="Aprobado">Aprobado</option>
                <option value="Rechazado">Rechazado</option>
                <option value="Pendiente">Pendiente</option>
            </select>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-primary" @click="handleSubmit()">Guardar</button>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import Security from '../security'

export default {
    name: 'EstadoSolicitud',
    props: {
        idSolicitud: String,
    },
    data() {
        return {
            estadoSolicitud: "",
        }
    },
    methods: {
        handleSubmit() {
            if (this.estadoSolicitud !== '') {

                const payload = {
                    id: parseInt(this.idSolicitud),
                    estado: this.estadoSolicitud,
                    idUsuario: Security.getUser()[0].user.id,
                }

                console.log(payload)
                Swal.fire({
                    title: '¿Estás Seguro del cambio de estado de la solicitud?',
                    icon: 'success',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Confirmar!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        console.log('confirmado')
                        // fetch(`${process.env.VUE_APP_API_URL}/admin/solicitud/update-estado`, {
                        //     method: 'POST',
                        //     headers: {
                        //         'Content-Type': 'application/json', // Indica que estás enviando datos en formato JSON
                        //         // Puedes incluir otros encabezados según tus necesidades
                        //     },
                        //     body: JSON.stringify(payload),
                        // })
                        //     .then((response) => response.json())
                        //     .then((data) => {
                        //         if (data.error) {
                        //             this.$emit('error', data.message)
                        //             Swal.fire({
                        //                 // position: 'top-end',
                        //                 icon: "error",
                        //                 title: data.message,
                        //                 showConfirmButton: false,
                        //                 timer: 1500,
                        //             });
                        //         } else {
                        //             //Info Correcta
                        //             Swal.fire({
                        //                 icon: "success",
                        //                 title: "Se ha Guardado Correctamente el estado Solicitud",
                        //                 showConfirmButton: false,
                        //                 timer: 1500,
                        //             });
                        //         }
                        //     })
                        //     .catch((error) => {
                        //         this.$emit('error', error);
                        //     })
                        
                        fetch(
                        process.env.VUE_APP_API_URL +
                        "/admin/solicitud/update-estado" ,
                        Security.requestOptions(payload)
                    )
                        .then((response) => response.json())
                        .then((data) => {
                        if (data.error) {
                            Swal.fire({
                                        // position: 'top-end',
                                        icon: "error",
                                        title: data.message,
                                        showConfirmButton: false,
                                        timer: 1500,
                            });
                        } else {
                           console.log(data.data);
                        }
                        });

                        location.reload()
                    }
                })
            }
        }
    }
}
</script>

<style scoped></style>