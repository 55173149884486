<template>
  <!-- partial:partials/_navbar.html -->
  <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
      <a class="navbar-brand brand-logo me-5" href="/"><img src="./../../public/images/logoNavidad.png"
          style="width: 100%; height: 90%;" class="me-2 ms-4" alt="logo" /></a>
      <a class="navbar-brand brand-logo-mini" href="/"><img src="./../../public/images/logo-mini.jpg"
          style="width: 100%; " alt="logo" /></a>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
      <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
        <!-- <span class="ti-view-list"></span> -->
        <!-- <span><i class="fa-solid fa-bars"></i></span> -->
        <span><i class="bi bi-list" style="font-size: 40px;"></i></span>
      </button>
      <ul class="navbar-nav mr-lg-2">
        <!-- <li class="nav-item nav-search d-none d-lg-block">
          <div class="input-group">
            <div class="input-group-prepend hover-cursor" id="navbar-search-icon">
              <span class="input-group-text" id="search">
                <i class="ti-search"></i>
              </span>
            </div>
            <input type="text" class="form-control" id="navbar-search-input" placeholder="Search now" aria-label="search"
              aria-describedby="search">
          </div>
        </li> -->
      </ul>
      <ul class="navbar-nav navbar-nav-right">

        <li class="nav-item dropdown">
          <!-- <a class="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#"
            data-bs-toggle="dropdown">
            <i class="ti-bell mx-0"></i>
            <span class="count"></span>
          </a>
          <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="notificationDropdown">
            <p class="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>
            <a class="dropdown-item">
              <div class="item-thumbnail">
                <div class="item-icon bg-success">
                  <i class="ti-info-alt mx-0"></i>
                </div>
              </div>
              <div class="item-content">
                <h6 class="font-weight-normal">Application Error</h6>
                <p class="font-weight-light small-text mb-0 text-muted">
                  Just now
                </p>
              </div>
            </a>
            <a class="dropdown-item">
              <div class="item-thumbnail">
                <div class="item-icon bg-warning">
                  <i class="ti-settings mx-0"></i>
                </div>
              </div>
              <div class="item-content">
                <h6 class="font-weight-normal">Configuración</h6>
                <p class="font-weight-light small-text mb-0 text-muted">
                  Private message
                </p>
              </div>
            </a>
            <a class="dropdown-item">
              <div class="item-thumbnail">
                <div class="item-icon bg-info">
                  <i class="ti-user mx-0"></i>
                </div>
              </div>
              <div class="item-content">
                <h6 class="font-weight-normal">New user registration</h6>
                <p class="font-weight-light small-text mb-0 text-muted">
                  2 days ago
                </p>
              </div>
            </a>
          </div> -->
        </li>
        <li class="nav-item nav-profile dropdown">
          <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" id="profileDropdown">
            <img src="../assets/user.png" alt="profile" />
          </a>
          <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
            <router-link class="nav-link" to="/ahdecoAdminSettings">
              <a v-if="this.Admin === true || this.Coordinacion === true" class="dropdown-item">
                <i class="ti-settings text-primary"></i>
                Configuración
              </a>
            </router-link>
            <a class="dropdown-item" href="javascript:void(0);" @click="logout">
              <i class="ti-power-off text-primary"></i>
              Cerrar Sesión
            </a>
          </div>
        </li>
      </ul>
      <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
        data-toggle="offcanvas">
        <!-- <span class="ti-view-list"></span> -->
        <span><i class="fa-solid fa-bars"></i></span>
        <!-- <span><i class="bi bi-list"></i></span> -->
      </button>
    </div>
  </nav>
</template>

<script>
import { store } from './store.js'
import router from './../router/index.js'
import Security from './security'
import 'bootstrap-icons/font/bootstrap-icons.css';

// import notie from 'notie'

export default {
  name: 'Header-App',
  data() {
    return {
      roles: ['Coordinacion', 'Admin', 'Finanzas'],
      Finanzas: false,
      Admin: false,
      Coordinacion: false,
      userRoles: []
    }
  },
  mounted() {
    this.userRoles = Security.getUserRoles();
    this.roles.forEach(element => {
      if (this.userRoles[0]?.some(role => role.includes(element)) === true) {
        this[element] = true;
      }
    });
  },
  methods: {
    logout() {
      const payload = {
        token: store.token,
      }

      fetch(process.env.VUE_APP_API_URL + "/users/logout", Security.requestOptions(payload))
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            console.log(response.message);
          } else {
            store.token = "";
            store.user = {};

            // Eliminar una cookie llamada "_site_data"
            document.cookie = "_site_data=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";


            // document.cookie = '_site_data=; Path=/; ' +
            // 'SameSite=Strict; Secure; ' +
            // 'Expires=Thu, 01 Jan 1970 00:00:01 GMT;'

            router.push("/");
            location.reload();
            window.location.href = "/";

          }
        })

    },

  }
}
</script>

<style>
.navbar-toggler {
  margin-top: 2px;
}

.navbar-toggler span i {
  font-size: 1.75rem;
}

.navbar-toggler span i:active {
  /* font-size: 1.8rem; */
  color: #ffbc58;
}

.navbar-toggler span i:hover {
  /* font-size: 1.8rem; */
  color: #EC9F2D;
}
</style>