<template>
    <div v-if="conversationId > 0" class="container d-flex flex-column justify-content-between">
        <div class="info-chat p-2 d-flex justify-content-between  align-items-center">
            <div class="d-flex align-items-center">
                <div class="circle-profile d-flex justify-content-center align-items-center">
                    <i class="fa-solid fa-user"></i>
                </div>

                <p v-if="!this.conversacion.cliente.nombres" class="ms-3" style="margin: 0;">{{
                    this.conversacion.participante1 }}</p>
                <p v-else class="ms-3" style="margin: 0;">{{ this.conversacion.cliente.nombres }} {{
                    this.conversacion.cliente.apellidos }} ({{ this.conversacion.participante1 }})</p>

            </div>

            <div class="me-4 d-flex align-items-center">
                <button @click="getMessages()" class=" btn-reload">
                    <i class="fa-solid fa-rotate-right"></i>
                </button>
            </div>


        </div>

        <div class="row scroll-container h-100" id="scroll" data-bs-spy="scroll">
            <div v-for="(mensaje) in mensajes" :key="mensaje.id">
                <div :class="'d-flex ' + (mensaje.esEnviado ? 'incommingMessage' : 'sendingMessage')">
                    <div class=" Message ">
                        <p v-html="formattedText(mensaje.message)"></p>

                        <div class="d-flex justify-content-end">
                            <p>{{ mensaje.createdAt ? formatTime(mensaje.createdAt) : '' }}</p>
                            <div style="font-size: 12px;" class="ms-2 d-flex justify-content-center mt-1">
                                <i v-if="mensaje.visto" class="fa-solid fa-check-double" style="color: #7389C1;"></i>
                                <i v-else class="fa-solid fa-check"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="send-message p-2 container-fluid d-flex justify-content-between  align-items-center">
            <div class="d-flex col-11 align-items-center">
                <input v-model="mensajeEnviar" type="text" placeholder="Escribe un mensaje" class=" ms-4 message w-100">
            </div>

            <div class="me-4 col-2 d-flex align-items-center">
                <button @click="sendMessage()" class=" btn-send">
                    <i class="fa-solid fa-paper-plane"></i>
                </button>
            </div>


        </div>
    </div>
    <div v-else class="container d-flex justify-content-center align-items-center">
        <div class="text-center">
            <h2 class=" edu-au-vic-wa-nt-pre-unique-style">
                <!-- <i class="fa-solid fa-paper-plane"></i> -->
                Ahdesistente
                <i class="fa-brands fa-square-whatsapp"></i>
            </h2>
            <p class=" edu-au-vic-wa-nt-pre-unique-style" style="color: white;">
                by AHDECO

            </p>
        </div>


    </div>
</template>

<script>
import Swal from 'sweetalert2';
import Security from '../security';

export default {
    data() {
        return {
            mensajes: [],
            ready: false,
            mensajeEnviar: "",
        }
    },
    props: {
        conversationId: Number,
        conversacion: Object,
    },
    updated() {
        this.ready = false
        console.log(1);

        const contenedor = document.getElementById('scroll'); // Reemplaza 'miContenedor' con el id de tu contenedor
        contenedor.scrollTop = contenedor.scrollHeight;
    },
    beforeMount() {
        this.getMessages();


    },
    methods: {
        formatTime(timestamp) {
            const date = new Date(timestamp);
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${hours}:${minutes}`;
        },
        formattedText(text) {
            if (!text) return ""; // Maneja casos donde el texto sea nulo o vacío
            return text.replace(/\*(.*?)\*/g, "<b>$1</b>");
        },
        getMessages() {

            let payload = {
                conversationId: this.conversationId,
            }
            console.log(payload);

            fetch(
                process.env.VUE_APP_API_URL +
                "/admin/whatsapp/get-messages",
                Security.requestOptions(payload)
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        Swal.fire({
                            icon: "error",
                            title: data.message + data.error,
                            showConfirmButton: false,
                            timer: 1500,
                        });

                    } else {

                        this.mensajes = data.data.messages

                        console.log("Mensajes: ", this.mensajes);



                    }
                });

            this.ready = true

        },
        sendMessage() {

            console.log(this.mensajeEnviar);
            let payload = {
                to: this.conversacion.participante1,
                from: this.conversacion.participante2,
                mensaje: this.mensajeEnviar,
            }
            console.log(payload);

            fetch(
                process.env.VUE_APP_API_URL +
                "/admin/whatsapp/send-messages",
                Security.requestOptions(payload)
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        Swal.fire({
                            icon: "error",
                            title: data.message + data.error,
                            showConfirmButton: false,
                            timer: 1500,
                        });

                    } else {

                        console.log("Mensaje Enviado: ", data);

                        let mensaje = {
                            createdAt: new Date(),
                            esEnviado: true,
                            from: "50494565133",
                            id: 21,
                            message: this.mensajeEnviar,
                            profileName: "AM",
                            to: this.conversacion.numero,
                            typeMessage: "text",
                            visto: true,
                        }

                        this.mensajes.push(mensaje)

                        this.mensajeEnviar = ''


                    }
                });

            // this.ready = true

        }
    }
}
</script>

<style scoped>
.container {

    /* background-image: url('@/assets/fondoWa.jpg'); */
    background-image: url('@/assets/fondoWa.jpg');
    /* background-image: url('@/assets/fondowa.png'); */
    background-size: cover;
    /* Asegura que la imagen cubra todo el fondo */
    background-position: center;
    /* Centra la imagen */
    background-repeat: no-repeat;
    /* Evita que la imagen se repita */
    width: 100%;
    border-radius: 10px;
    /* Ajusta el ancho del contenedor */
    height: 100%;
    /* Ajusta el alto del contenedor */
    padding: 0;
    margin: 0;
}

#scroll {
    overflow-y: scroll;
    /* height: 60vh; */
}

.info-chat {
    border-radius: 10px 10px 0px 0px;
    background: #2e3951;
    /* width: 100%; */
    color: white;

}

.send-message {
    border-radius: 0px 0px 10px 10px;
    background: #2e3951;
    /* width: 100%; */
    color: white;

}

.circle-profile {
    color: #7389c1;
    width: 40px;
    /* Ancho del círculo */
    height: 40px;
    /* Alto del círculo */
    border-radius: 50%;
    /* Hacer las esquinas redondeadas al 50% */
    background-color: #3498db52;
    /* Color de fondo, puedes cambiarlo */
}

.Message {
    border: #3498db52;
    color: white;
    /* background-color: #2a344b; */
    width: 45%;
    margin: 15px;
    padding: 10px;
    border-radius: 5px;
}

.sendingMessage {
    justify-content: start;
}

.sendingMessage .Message {
    background-color: #3c4763;
}

.incommingMessage {
    justify-content: end;
}

.incommingMessage .Message {
    background-color: #005C4B;
}

h2 {
    color: white;
}

/* Scroll */

/* Scroll personalizado para navegadores basados en Webkit */
::-webkit-scrollbar {
    width: 12px;
    /* Ancho del scroll vertical */
    height: 12px;
    /* Alto del scroll horizontal */
}

::-webkit-scrollbar-track {
    background: #ffffff00;
    /* Fondo del track */
    border-radius: 10px;
    /* Bordes redondeados */
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, #2E3951, #2575fc);
    /* Color degradado del scroll */
    border-radius: 10px;
    /* Bordes redondeados */
}

::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(45deg, #2E3951, #1e6ae6);
    /* Cambio de color al pasar el mouse */
}

/* Scroll personalizado para Firefox */
* {
    scrollbar-width: thin;
    /* Grosor del scrollbar */
    scrollbar-color: #2E3951 #ffffff1b;
    /* Color del thumb y track */
}

/* Scroll dentro de un contenedor específico */
.scroll-container {
    /* max-height: 400px; Altura máxima del contenedor */
    overflow-y: auto;
    /* Activar scroll vertical */
    /* padding: 10px; Espaciado interno */
    /* border: 1px solid #ddd; Bordes del contenedor */
    border-radius: 8px;
    /* Bordes redondeados */
    /* background-color: #fff; Fondo del contenedor */
    width: 100%;
    height: 40%;
    margin: 0;
}


.edu-au-vic-wa-nt-pre-unique-style {
    font-family: "Edu AU VIC WA NT Pre", cursive;
    font-optical-sizing: auto;
    font-weight: 200;
    /* Cambia el valor según lo que necesites */
    font-style: thin;
}

.btn-reload {
    margin: 0;
    border-radius: 5px;
    border: 0px;
    background-color: #3c4763;
    padding-inline: 15px;
    padding-block: 5px;
}

.btn-reload:hover {

    background-color: #434e6b;

}

.btn-reload:active {

    background-color: #515e81;

}

.btn-send {
    margin: 0;
    height: 35px;
    border-radius: 0px 5px 5px 0px;
    border: 0px;
    background-color: #6f85bc;
    padding-inline: 15px;
    padding-block: 5px;
}

.btn-send:hover {

    background-color: #85a1ee;

}

.btn-send:active {

    background-color: #515e81;

}

.message {
    height: 35px;
    border-radius: 5px 0px 0px 5px;
    background-color: #434e6b;
    border: none;
    color: white;
    padding-left: 6px;
    font-size: 13px;
}
</style>