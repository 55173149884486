

<template>
  <nav class="sidebar sidebar-offcanvas" id="sidebar">
    <ul class="nav">
      <li class="nav-item">
        <a class="nav-link" href="/">
          <i class="fa-solid fa-house menu-icon"></i>
          <span class="menu-title notranslate">Home</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/dashboard">
          <i class="fa-solid fa-chart-line menu-icon"></i>
          <span class="menu-title notranslate">Dashboard</span>
        </a>
      </li>
      <li v-if="Admin || Coordinacion || Asesor" class="nav-item">
        <a class="nav-link" data-bs-toggle="collapse" href="#ui-basic-2" aria-expanded="false" aria-controls="ui-basic-2">
          <i class="fa-solid fa-address-book menu-icon"></i>
          <span class="menu-title">Beneficiarios</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="collapse" id="ui-basic-2">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item">
              <router-link class="nav-link" to="/solicitante/nuevo">Crear Beneficiarios</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/mostrar-solicitantes">Beneficiarios</router-link>
            </li>
          </ul>
        </div>
      </li>
      <li v-if="Admin || Coordinacion || Asesor || coordinadorSucursal || Finanzas" class="nav-item">
        <a class="nav-link" data-bs-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
          <i class="fa-solid fa-pencil menu-icon"></i>
          <span class="menu-title">Solicitudes</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="collapse" id="ui-basic">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item">
              <router-link class="nav-link" to="/solicitud">Nueva Solicitud</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/mostrar-solicitudes">Solicitudes</router-link>
            </li>
            <li v-if="Admin || Finanzas" class="nav-item">
              <router-link class="nav-link" to="/mostrar-solicitudes-aceptadas">Desembolsar</router-link>
            </li>
          </ul>
        </div>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/cartera">
          <i class="fa-solid fa-wallet menu-icon"></i>
          <span class="menu-title">Cartera</span>
        </router-link>
      </li>
      <li v-if="oficinaGeneral" class="nav-item">
        <router-link class="nav-link" to="/recordatorios">
          <i class="bi bi-calendar-date menu-icon"></i>
          <span class="menu-title">Recordatorios</span>
        </router-link>
      </li>
      <li v-if="Admin || Finanzas || Consultor" class="nav-item">
        <router-link class="nav-link" to="/pagos">
          <i class="fa-regular fa-credit-card menu-icon"></i>
          <span class="menu-title">Pagos</span>
        </router-link>
      </li>
      <li class="nav-item" >
        <router-link class="nav-link" to="/recordatorios" >
          <i class="fa-solid fa-calendar-days menu-icon"></i>
          <span class="menu-title">Recordatorios</span>
        </router-link>
      </li>
      <li v-if="Admin" class="nav-item" >
        <router-link class="nav-link" to="/whatsapp" style="color: green">
          <i class="fa-brands fa-whatsapp menu-icon" style="color: green"></i>
          <span class="menu-title">Whatsapp</span>
        </router-link>
      </li>
      <li v-if="Admin" class="nav-item">
        <router-link class="nav-link" to="/ahdecoAdminSettings">
          <i class="fa-solid fa-users-gear menu-icon"></i>
          <span class="menu-title">Gestión de Usuarios</span>
        </router-link>
      </li>
      <li v-if="Admin" class="nav-item">
        <router-link class="nav-link" to="/registro-actividades">
          <i class="fa-solid fa-gauge-high menu-icon"></i>
          <span class="menu-title">Registro de Actividades</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import Security from './security';

export default {
  name: 'Navbar-Lateral',
  data() {
    return {
      roles: ['Coordinacion', 'Admin', 'Finanzas', 'Asesor', 'Coordinador Sucursal', 'Consultor', 'oficinaGeneral'],
      Finanzas: false,
      coordinadorSucursal: false,
      Admin: false,
      Coordinacion: false,
      Asesor: false,
      Consultor: false,
      oficinaGeneral: false,
      userRoles: [],
    };
  },
  mounted() {
    // Obtener roles del usuario y configurar dinámicamente las variables de acceso
    this.userRoles = Security.getUserRoles();
    this.roles.forEach((role) => {
      if (this.userRoles[0]?.some((userRole) => userRole.includes(role))) {
        this[role] = true;
      }
    });
  },
};
</script>

<style>
.fa-solid {
  /* Define estilos adicionales si es necesario */
}
</style>
