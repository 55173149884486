<template>

    <div class="container mt-5">
        <h2 class="text-center">Simular Pagos</h2>
        <div class="text-center">
            <p v-if="pagos[0]?.cliente?.nombres">{{ pagos[0].cliente.nombres }}  </p>
            <p v-if="pagos[0]?.cliente?.apellidos">  {{ pagos[0].cliente.apellidos }}</p>
        </div>



        <div style="overflow-x: auto; overflow-y: auto; width: 100%;">
            <!-- <table border="1" cellspacing="0" cellpadding="10" class="table"> -->
                <div class="d-flex justify-content-between">
                    <button class="btn btn-light m-1" @click="masDetalles()">{{ ((this.moreDetails == true)? "Menos detalles": "Más detalles") }}</button>
                    <button class="btn btn-primary m-1" @click="UpdateCredito()" style="color: white">Actualizar Crédito</button>

                </div>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Pago</th>
                        <th class="table-info">Monto</th>
                        <!-- <th>Fecha Pago</th> -->
                        <th>Fecha Pago</th>
                        <th v-if="moreDetails">Días de Interes</th>
                        <th v-if="moreDetails">Interes Pagado</th>
                        <th v-if="moreDetails">Días de Mora</th>
                        <th v-if="moreDetails">Mora Pagada</th>
                        <th v-if="moreDetails">No Cuotas</th>
                        <th v-if="moreDetails">Fecha Cuota s/p</th>
                        <th v-if="moreDetails">Pago al día</th>
                        <th v-if="moreDetails">Capital Atrasado</th>
                        <th v-if="moreDetails">Capital Deberia Acumulado</th>
                        <th class="table-info">Total Capital Acumulado</th>
                        <th class="table-info">Total Interés Pagado</th>
                        <th class="table-info">Total Mora Pagada</th>
                        <th class="table-info">Total Pagado</th>
                        <th>Saldo</th>
                    </tr>
                </thead>
                <tbody class="table-group-divider">
                    <tr v-for="(pago, index) in pagos" :key="index">
                        <td>{{ index }}</td>
                        <td class="table-secondary">{{ formatoNumero(pagosMonto[index - 1] ? pagosMonto[index -
                            1].montoPagado : 0) }} {{
                                this.moneda }}</td>
                        <!-- <td>{{ pagosMonto[index] ? pagosMonto[index].fechaPagado : 0 }}</td> -->
                        <td>{{ this.dateFormat(pago.fechaUltimaPago) }} </td>
                        <td v-if="moreDetails">{{ pago.diasInteres }}</td>
                        <td v-if="moreDetails">{{ formatoNumero(pago.interesPorPagar) }} {{ this.moneda }}</td>
                        <td v-if="moreDetails">{{ pago.diasMora }}</td>
                        <td v-if="moreDetails">{{ formatoNumero(pago.moraPorPagar) }} {{ this.moneda }}</td>
                        <td v-if="moreDetails">{{ pago.noCuotas - 1 }}</td>
                        <td v-if="moreDetails">{{ this.dateFormat(pago.fechaCuotaSinPagar) }}</td>
                        <td v-if="moreDetails">{{ formatoNumero(pago.minimoDebePagar) }} {{ this.moneda }}</td>
                        <td v-if="moreDetails">{{ formatoNumero(pago.capitalDebePagar) }} {{ this.moneda }}</td>
                        <td v-if="moreDetails">{{ formatoNumero(pago.capitalDeberiaAcumulado) }} {{ this.moneda }}</td>
                        <td class="table-secondary">{{ formatoNumero(pago.capitalAcumulado) }} {{ this.moneda }}</td>
                        <td class="table-secondary">{{ formatoNumero(pago.totalInteresPagado) }} {{ this.moneda }}</td>
                        <td class="table-secondary">{{ formatoNumero(pago.totalMoraPagada) }} {{ this.moneda }}</td>
                        <td class="table-secondary">{{ formatoNumero(pago.totalPagado) }} {{ this.moneda }}</td>
                        <td>{{ formatoNumero(pago.saldo) }} {{ this.moneda }}</td>
                    </tr>
                    <!-- Fila de totales -->
                    <tr>
                        <td class=""><strong>Total</strong></td>
                        <td class="">{{ formatoNumero(totales.monto) }} {{ this.moneda }} </td>

                    </tr>
                </tbody>
            </table>
        </div>




    </div>
</template>

<script>
import Swal from 'sweetalert2';
import Security from '../security';

export default {
    data() {
        return {
            creditoId: 0,
            pagos: [],
            pagosMonto: [],
            moneda: "lps",
            moreDetails: false,
        }
    },
    beforeMount() {
        this.idCredito = this.$route.params.creditoId

        let payload = {
            idCredito: parseInt(this.idCredito, 10)
        }

        fetch(process.env.VUE_APP_API_URL + `/admin/pagos/simular-pagos`, Security.requestOptions(payload))
            .then((response) => response.json())
            .then((response) => {
                if (response.error) {
                    this.$emit('error', response.message);
                    console.error(response.message)
                } else {
                    console.log('Simulador de pagos:', response)
                    this.pagos = response.data

                }
            })
            .catch(error => {
                this.$emit('error', error)
            })

        let payload2 = {
            idCredito: parseInt(this.idCredito, 10)
        }
        fetch(process.env.VUE_APP_API_URL + `/admin/pagos/allById`, Security.requestOptions(payload2))
            .then((response) => response.json())
            .then((response) => {
                if (response.error) {
                    Swal.fire({
                        position: 'top-end',
                        icon: "error",
                        title: response.error,
                        showConfirmButton: false,
                        timer: 1500,
                    });

                } else {

                    // this.pagos = response.data.pagos.reverse();
                    this.pagosMonto = response.data.pagos.reverse();
                    console.log('Pagos Hecho:', this.pagosMonto);
                }
            })
            .catch(error => {
                this.$emit('error', error);
            });
    },
    methods: {
        masDetalles(){
            if (this.moreDetails) {
                this.moreDetails = false
            }else{
                this.moreDetails = true
            }
            
        },
        UpdateCredito(){
            Swal.fire({
                title: '¿Estás Seguro que deseas Actualizar el credito?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmar!'
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log('El ultimo pago es:' , this.pagos[this.pagos.length-1])
                    
                    let payload =  {
                        credito: this.pagos[this.pagos.length-1]
                    }

                    fetch(
                        process.env.VUE_APP_API_URL +
                        "/admin/credito/update-simular-pagos",
                        Security.requestOptions(payload)
                    )
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                Swal.fire({
                                    icon: "error",
                                    title: data.message + data.error,
                                    showConfirmButton: false,
                                    timer: 1500,
                                });

                            } else {

                                console.log(data.data);
                                Swal.fire({
                                    icon: "success",
                                    title: "Credito actualizado Exitosamente!",
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                                // Recarga la página actual
                                    location.reload();



                            }
                        });



                }
            })
            
        },
        dateFormat(param) {

            var fechaISO = param;

            // Crear un objeto Date con la fecha proporcionada
            var fecha = new Date(fechaISO);

            // Obtener el día del mes (agregando un cero inicial si es necesario)
            var dia = ('0' + fecha.getUTCDate()).slice(-2);

            // Obtener el mes (agregando un cero inicial si es necesario)
            var mes = ('0' + (fecha.getUTCMonth() + 1)).slice(-2);

            // Obtener el año
            var anio = fecha.getUTCFullYear();

            // Formatear la fecha según tus necesidades
            var fechaFormateada = dia + '/' + mes + '/' + anio;

            // console.log(fechaFormateada); // Salida: 01/12/2023

            return fechaFormateada;

        },
        formatoNumero(numero) {
            return new Intl.NumberFormat("en-US").format(numero)
        },
    },
    computed: {
        totales() {
            return {
                monto: this.pagosMonto.reduce((acc, pago) => acc + pago.montoPagado, 0),

            };
        }
    }
}
</script>

<style></style>