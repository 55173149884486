<template>

  <button @click="getCreditosOptimizados()" class="btn btn-success m-2" style="color: aliceblue; background-color: #00a632">Generar Excel <i
      class="fa-solid fa-download"></i></button>







  <!-- </div> -->
  <!-- FIN DE MODAL -->
 

</template>

<script>
import ExcelJS from "exceljs";
import jsPDF from 'jspdf';
//import { DataTable } from "simple-datatables"
import DataTables from "datatables.net";
import { solicitudCompleta } from '../solicitud.js'
import Security from "../security";
import router from '@/router';
import Swal from "sweetalert2";
import direccionData from '../solicitud/direccionData.json';

// import Security from '../security';
export default {
  name: "Cartera-App",
  data() {
    return {
      bancos: [
        {
          nombreBanco: 'Atlantida',
          cuentas: [
            '10120625735',
            '10120625743',
            '10120625750',
            '10120625750',
            '10111039284',
            '10120625768'
          ],
        },
        {
          nombreBanco: 'Ficohsa',
          cuentas: [
            '200014395554',
            '200014395619',
            '200014395759',
            '200014396542'
          ]
        },
        {
          nombreBanco: 'Banrural',
          cuentas: [
            '15110490970',
            '15110491370',
            '15110491384',
            '15110491398',
            '15110496114',
            '15110496132',
            '15110496146',
            '15310166680',
            '15310167484',
          ]
        },
        {
          nombreBanco: 'Crédito Solidario',
          cuentas: [
            '21-401-177888-4',
            '02701010052409',
          ]
        },
        // {
        //   nombreBanco: 'Crédito Solidario Banrural',
        //   cuentas: [
        //     '02701010052409',
        //   ]
        // },
        // {
        //   nombreBanco: 'Crédito Solidario Occidente',
        //   cuentas: [
        //     '21-401-177888-4',
        //   ]
        // },
        // {
        //   nombreBanco: 'Crédito Solidario Banrural',
        //   cuentas: [
        //     '02701010052409',
        //   ]
        // },
      ],
      imgPath: process.env.VUE_APP_IMAGE_URL,
      slug: "",
      noCuenta: '',
      numerosDeCuenta: [],
      esRefinanciado: false,
      nombreBanco: '',
      fechaPagado: '',
      moneda: 'lps',
      departamentos: [],
      montoPagado: 0,
      idCredito: 0,
      fechaUltimoPago: '',
      isLoading: false,
      sendImageToServer: false,
      fechaDataUltimoPago: '',
      noReferenciaRecibo: '',
      recibo: null,
      estado: 'Activo',
      hacerPagoInfo: {},
      solicitudCompleta,
      creditos: [],
      creditosOptimizados: [],
      data: [],
      dateFormated: "",
      ready: false,
      imagenPegada: null,
      readyExcel: false,
      filtradoMostrar: "",
      verMasData: [],
      depto: '',
      solicitud: {},
      sucursalesRoles: [],
      pagos: [],
      sucursal: '',
      roles: ['Coordinacion', 'Admin', 'Finanzas', 'Asesor', 'Cordinador Sucursal', 'Consultor'],
      Finanzas: false,
      Admin: false,
      Coordinacion: false,
      Asesor: false,
      coordinadorSucursal: false,
      modoThanos: false,
      Consultor: false,
      readyVerMas: false,
      userRoles: [],

      contadorArchivos: 0,
    };
  },
  props: {
    sucursalFiltro: String,
    estadoFiltro: String,

  },
  
  /*   beforeMount() {
      this.getCreditos();
   }, */
  beforeMount() {


    this.userRoles = Security.getUserRoles();
    // console.log(this.userRoles);
    this.roles.forEach(element => {

      if (this.userRoles[0]?.some(role => role.includes(element)) === true) {
        this[element] = true;
      } else if (this.userRoles[0]?.some(role => role.includes('Coordinador Sucursal')) === true) {
        this.coordinadorSucursal = true;
        // console.log('coordinador sucursal en true')
      } else if (this.userRoles[0]?.some(role => role.includes('Modo Thanos')) === true) {
        this.modoThanos = true;
        // console.log('Modo thanos en true')
      }
      /*       if (this.userRoles[0]?.some(role => role.includes('Coordinador Sucursal')) === true) {
            } */


    });

    this.sucursalesRoles = Security.getUserSucursal()
    //Sucursales
    this.sucursal = 'MOSTRAR TODAS'
    if (this.Admin || this.coordinador) {
      this.departamentos = direccionData[0].departamentos
    } else {
      // console.log('Sucursales de La persona');
      const sucursales = Security.getUserSucursal();
      this.departamentos = sucursales[0];
    }

    console.log("Departamentos", this.departamentos);

    // this.getCreditosOptimizados()


  },
  updated() {
    if (this.ready) {
      this.initializeDataTable();
    }
  },

  methods: {
    closeModal() {
      const backdrop = document.querySelector('.modal-backdrop');
      document.getElementById("resumenCredito").classList.remove("show")
      if (backdrop) {
        backdrop.remove(); // Elimina el elemento del DOM
      }
    },
    eliminarPago(idPago) {
      Swal.fire({
        title: '¿Confirmar Data?',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Confirmar! `
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(idPago)

          // let payload = {
          //   idPago: idPago
          // }

          // fetch(process.env.VUE_APP_API_URL + `/admin/pagos/eliminar-pago`, Security.requestOptions(payload))
          //   .then((response) => response.json())
          //   .then((response) => {
          //     if (response.error) {
          //       this.$emit('error', response.message);
          //       console.error(response.message)
          //     } else {
          //       console.log('Credito:', response)
          //       // location.reload();
          //     }
          //   })
          //   .catch(error => {
          //     this.$emit('error', error)
          //   })
        }
      })
    },

    creditrabajo2() {
      return this.hacerPagoInfo.producto === 'Credi Trabajo 2'

    },
    esAgricola(producto) {
      // Convertir a minúsculas y eliminar espacios
      producto = producto.trim().toLowerCase();

      if (producto === "agricola") {
        console.log("Es agricola");
        return true;
      } else {
        return false;
      }
    },

    sumarMeses(fecha, meses) {
      const nuevaFecha = new Date(fecha);
      nuevaFecha.setMonth(nuevaFecha.getMonth() + meses);
      console.log(nuevaFecha);
      return nuevaFecha;
    },

    loadCoverImage() {
      // //get a refernce to the input using ref
      // const file = this.$refs.coverInput.files[0];

      // //encode the file using the FileReader API
      // const reader = new FileReader();
      // reader.onloadend = () => {
      //   const base64String = reader.result
      //     .replace("data", "")
      //     .replace(/^.+,/, "");
      //   this.recibo = base64String;
      //   // alert(base64String)
      // }
      // reader.readAsDataURL(file);

      //   const file = this.$refs.coverInput.files[0];

      //   if (!file) {
      //     // No se seleccionó ningún archivo
      //     return;
      //   }

      //   // Verifica si el tipo de archivo es compatible
      //   const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      //   if (!allowedTypes.includes(file.type)) {
      //     alert('El formato de archivo seleccionado no es válido. Por favor, selecciona una imagen JPEG, JPG o PNG.');
      //     return;
      //   }

      //   const reader = new FileReader();

      //   // Manejo de eventos onloadstart, onloadend y onerror
      //   reader.onloadstart = () => {
      //     // Puedes mostrar un indicador de carga aquí si lo deseas
      //     this.isLoading = true;
      //   };

      //   reader.onloadend = () => {
      //     try {
      //       // Actualizar la variable recibo con la imagen base64
      //       this.recibo = reader.result.replace("data", "").replace(/^.+,/, "");

      //       // Oculta el indicador de carga al finalizar la carga
      //       this.isLoading = false;

      //       // Aquí la imagen está lista para ser enviada, puedes llamar a la función para enviarla al servidor
      //       this.sendImageToServer = true;
      //     } catch (error) {
      //       console.error('Error al cargar la imagen:', error);
      //       alert('Ocurrió un error al cargar la imagen. Por favor, inténtalo de nuevo.');
      //     }
      //   };

      //   reader.onerror = () => {
      //     console.error('Error al cargar la imagen:', reader.error);
      //     alert('Ocurrió un error al cargar la imagen. Por favor, inténtalo de nuevo.');
      //   };

      //   // Leer el archivo como una URL de datos
      //   reader.readAsDataURL(file);
      // },


      this.recibo = event.target.files[0];
      this.imagenPegada = null
    },
    comprobarEstado(estado) {
      if (this.mayusculasSinEspacios(estado) === 'CANCELADO') {
        return true
      } else return false
    },
    comprobarCrediTrabajo(producto) {

      if (producto === 'Credi Trabajo' || producto === 'Credi Trabajo' || producto === 'Creditrabajo ' || producto === 'credi trabajo' || producto === 'CREDI TRABAJO' || producto === 'CREDITRABAJO' || producto === 'creditrabajo' || producto === 'CREDITRABAJO ' || producto === 'creditrabajo ' || producto === 'Creditrabajo ' || producto === 'Credi Trabajo ' || producto === ' Credi trabajo' || producto === 'credi trabajo ' || producto === 'Credi trabajo' || producto === ' credi trabajo') {
        return true
      } else {
        return false
      }
    },
    async pegarImagen() {
      try {
        // Pegar la imagen desde el portapapeles
        const clipboardItems = await navigator.clipboard.read();
        for (const clipboardItem of clipboardItems) {
          for (const type of clipboardItem.types) {
            if (type.startsWith('image/')) {
              const blob = await clipboardItem.getType(type);
              this.imagenPegada = URL.createObjectURL(blob);
              this.recibo = this.imagenPegada
              return;
            }
          }
        }
        console.log('No se encontró ninguna imagen en el portapapeles.');
      } catch (error) {
        console.error('Error al pegar la imagen:', error);
      }
    },
    handleImprimirEstadoCuenta() {
      const doc = new jsPDF()

      this.agregarTextoCentrado('Estado de Cuenta', 0, 30, doc, 13)
      this.agregarTextoCentrado(this.fechaPagado, 0, 35, doc, 10)
      // this.agregarTextoCentrado(`Fecha de Elaboración: ${new Date()}`,0 , 50 , doc)
      this.agregarTextoCentrado(`${this.hacerPagoInfo.nombres} ${this.hacerPagoInfo.apellidos}`, 0, 45, doc, 12)


      this.agregarTexto(`Fecha de emisión: ${this.dateFormat(new Date())}`, 20, 160, doc, 10)
      this.agregarTexto('Tasa:', 20, 70, doc, 10)
      this.agregarTexto(`${this.hacerPagoInfo.tasaInteres} %`, 60, 70, doc, 10)
      this.agregarTexto('Fecha de Desembolso:', 20, 75, doc, 10)
      this.agregarTexto(`${this.dateFormat(this.hacerPagoInfo.fechaDesembolso)}`, 60, 75, doc, 10)
      this.agregarTexto('Monto del Crédito:', 20, 80, doc, 10)
      this.agregarTexto(`${this.formatoNumero(this.hacerPagoInfo.montoAprobado)} ${this.moneda}`, 60, 80, doc, 10)
      this.agregarTexto('Total Pagado:', 20, 85, doc, 10)
      this.agregarTexto(`${this.formatoNumero(this.hacerPagoInfo.capitalAcumulado + this.hacerPagoInfo.totalInteresPagado + this.hacerPagoInfo.totalMoraPagada)}  ${this.moneda}`, 60, 85, doc, 10)
      // this.agregarTextoCentrado('Fecha Desembolsado', -10, 70, doc, 12)

      // Define los encabezados y datos de la tabla
      // const headers = [['Saldo a la fecha']];
      this.agregarTextoCentrado('Saldo a la fecha:', 0, 100, doc, 12)

      const data = [
        ['Interes Corriente:', `${this.formatoNumero(this.hacerPagoInfo.interesPorPagar)} ${this.moneda}`],
        ['Interes Moratorio', `${this.formatoNumero(this.hacerPagoInfo.moraPorPagar)} ${this.moneda}`],
        ['Capital Pendiente', `${this.formatoNumero(this.hacerPagoInfo.capitalDebePagar)} ${this.moneda}`]
      ];
      const footer = [['Total a pagar', `${this.formatoNumero(this.hacerPagoInfo.totalMinimoAPagar)} ${this.moneda}`]];
      const footer2 = [['Total para Cancelar Crédito              ', `${this.formatoNumero(this.hacerPagoInfo.totalMontoCancelar)} ${this.moneda}`]];

      // Crea la tabla en el documento
      doc.autoTable({
        // head: headers,
        foot: footer,
        body: data,
        startY: 100
      });

      doc.autoTable({
        // head: headers,
        foot: footer2,
        // body: data,
        startY: 135
      });

      doc.setFont('courier')

      // Calcula las dimensiones del documento
      const docWidth = doc.internal.pageSize.getWidth();
      // Agrega una imagen al documento
      const img = new Image();
      img.src = require('@/assets/header.png'); // Reemplaza 'mi_imagen.jpg' con el nombre de tu imagen
      // Calcula las dimensiones de la imagen para que ocupe todo el ancho de la página
      const imgWidth = docWidth;
      // const imgHeight = (img.height * imgWidth) / img.width;
      // doc.addImage(img, 'PNG', 10, 10, 300, 200); // Ajusta las coordenadas y el tamaño según tu necesidad
      doc.addImage(img, 'PNG', 0, 0, imgWidth, 30);

      // //        // Agregar texto al final de la página
      // let cuentaText = `Cuenta No. 15310167484 de BANRURAL`; // Tu texto aquí
      // const textWidth2 = doc.getTextWidth(cuentaText);
      // const centerX2 = (doc.internal.pageSize.width - textWidth2) / 2;
      // const bottomY = doc.internal.pageSize.height - 60; // Ajusta la posición vertical según tus necesidades
      // doc.text(cuentaText, centerX2, bottomY);

      // // Agregar texto al final de la página
      // let cuentaText3 = `_____________`; // Tu texto aquí
      // const textWidth3 = doc.getTextWidth(cuentaText3);
      // const centerX3 = (doc.internal.pageSize.width - textWidth3) / 2;
      // const bottomY3 = doc.internal.pageSize.height - 30; // Ajusta la posición vertical según tus necesidades
      // doc.text(cuentaText3, centerX3, bottomY3);
      // // Agregar texto al final de la página
      // let cuentaText4 = `FIRMA`; // Tu texto aquí
      // let textWidth4 = doc.getTextWidth(cuentaText4);
      // let centerX4 = (doc.internal.pageSize.width - textWidth4) / 2;
      // let bottomY4 = doc.internal.pageSize.height - 20; // Ajusta la posición vertical según tus necesidades
      // doc.text(cuentaText4, centerX4, bottomY4);
      // Guardar el PDF
      doc.save(`Estado de Cuenta-${this.hacerPagoInfo.nombres} ${(this.hacerPagoInfo.apellidos ? this.hacerPagoInfo.apellidos : "")}.pdf`)
    },
    agregarTextoCentrado(text, ancho, altura, doc, fontSize) {
      doc.setFontSize(fontSize);

      const textWidth = doc.getTextWidth(text);
      // // Calcular la posición para centrar el texto

      const centerX = (doc.internal.pageSize.width - textWidth) / 2;
      const y = 10;


      // // Colocar el texto centrado
      doc.text(text, centerX + ancho, y + altura);
    }
    ,
    agregarTexto(text, ancho, altura, doc, fontSize) {
      doc.setFontSize(fontSize);

      // const textWidth = doc.getTextWidth(text);
      // // Calcular la posición para centrar el texto

      // const centerX = (doc.internal.pageSize.width - textWidth) / 2;
      // const y = 10;


      // // Colocar el texto centrado
      doc.text(text, ancho, altura);
    },
    handleImprimirPlanPago() {
      const doc = new jsPDF()

      // const text = 'PLAN DE PAGO';
      // const textWidth = doc.getTextWidth(text);
      // this.agregarTextoCentrado(`Plan de Pago`, 0, 30, doc, 14)
      const text = 'PLAN DE PAGO';
      // const textWidth = doc.getTextWidth(text);
      // const centerX = (doc.internal.pageSize.width - textWidth) / 2;
      let y = 20;
      // var tableHeight

      // Calcula las dimensiones del documento
      const docWidth = doc.internal.pageSize.getWidth();
      // Agrega una imagen al documento
      const img = new Image();
      img.src = require('@/assets/header.png'); // Reemplaza 'mi_imagen.jpg' con el nombre de tu imagen
      // Calcula las dimensiones de la imagen para que ocupe todo el ancho de la página
      const imgWidth = docWidth;
      // const imgHeight = (img.height * imgWidth) / img.width;
      // doc.addImage(img, 'PNG', 10, 10, 300, 200); // Ajusta las coordenadas y el tamaño según tu necesidad
      doc.addImage(img, 'PNG', 0, 0, imgWidth, 30);
      doc.setFontSize(12)
      doc.setTextColor(255, 255, 255);
      doc.text(text, 130, (y - 6));

      // Calcular la posición para centrar el texto
      // const centerX = (doc.internal.pageSize.width - textWidth) / 2;
      y = 30;

      // Colocar el texto centrado
      // doc.text(text, centerX, y);
      this.agregarTextoCentrado(`${this.hacerPagoInfo.nombres} ${this.hacerPagoInfo.apellidos}`, 43, 10, doc, 11)

      doc.setFont('courier')


      const tableData = [];
      const tableInfoPrestamo = [];
      document.querySelectorAll('.datos-table tr').forEach((row) => {
        const rowData = [];
        // Iterar sobre las celdas de cada fila
        row.querySelectorAll('td').forEach((cell) => {
          rowData.push(cell.innerText);
        });

        tableData.push(rowData);
      });

      document.querySelectorAll('.datos-prestamo-table tr').forEach((row) => {
        const rowData = [];
        // Iterar sobre las celdas de cada fila
        row.querySelectorAll('td').forEach((cell) => {
          rowData.push(cell.innerText);
        });

        tableInfoPrestamo.push(rowData);
      });
      this.ejecutarPlanPagos()

      // Crear la tabla en el PDF
      doc.autoTable({
        body: tableData,
        theme: 'striped', // Puedes cambiar el tema según tus preferencias
        styles: {
          halign: 'center',
        },
      });
      doc.autoTable({
        body: tableInfoPrestamo,
        theme: 'striped', // Puedes cambiar el tema según tus preferencias
        styles: {
          halign: 'left',
        },
      });
      doc.autoTable({
        head: [['No. Cuota', 'Fecha de Pago', 'Cuota Total', 'Interes', 'Capital', 'Saldo']],
        headStyles: { fillColor: [41, 128, 185] },
        body: this.planPagoToPrint.map(plan => [plan.noCuota, plan.fechaPago, plan.cuotaTotal, plan.interes, plan.capital, plan.saldo]),
        theme: 'grid',
        styles: {
          halign: 'center',
        },
      })



      // Guardar el PDF
      doc.save(`${(this.hacerPagoInfo.nombres).trim()}-Plan de Pago.pdf`);
    },

    // CalculaCuotaNivelada calcula la cuota nivelada de un préstamo
    CalculaCuotaNivelada(
      montoPrestamo,
      tasaInteres,
      periodos,
      periodo,
      diasPeriodo
    ) {
      if (periodo === 24) {
        periodos *= 2;
      } else if (periodo === 52.1) {
        periodos *= 4.33;
        periodos = Math.round(periodos);
      } else if (periodo === (30 / (periodos * 30)) * 12) {
        periodos = 1
      } else if (periodo === 4) {
        periodos /= 3;
        // periodos = Math.round(periodos);
      } else if (periodo === 2) {
        periodos /= 6;
        // periodos = Math.round(periodos);
      } else if (periodo === 1) {
        periodos /= 12;
        // periodos = Math.round(periodos);
      }

      let tasaMensual = (tasaInteres / 360) * diasPeriodo;
      tasaMensual = Math.round(tasaMensual * 10000000000) / 10000000000;

      let cuota =
        (montoPrestamo * tasaMensual) / (1 - Math.pow(1 + tasaMensual, -periodos));
      return cuota;
    },
    stringToDate(dateString) {
      // Cadena con formato mm/dd/yyyy
      // Diseño que coincide con el formato de la cadena
      let date = new Date(dateString.split("/").join("-").slice(0, 10));
      date.toLocaleDateString("es-ES", { year: 'numeric', month: '2-digit', day: '2-digit' })
      return date;
    },
    redirect() {
      //router.push(`/soli-credito/${this.verMasData.solicitud?.id}`)
      window.location.href = `/soli-credito/${this.verMasData.id}`
    },
    ejecutarPlanPagos() {
      const tablaBody = document.getElementById('tablaPlanPagos')
      const solicitud = {
        fechaPago: this.hacerPagoInfo.fechaDesembolso,
        monto: this.hacerPagoInfo.montoAprobado,
        frecuenciaPago: this.hacerPagoInfo.frecuenciaPago,
        interes: this.hacerPagoInfo.tasaInteres,
        plazoPago: this.hacerPagoInfo.plazoPago,
        producto: this.hacerPagoInfo.producto
      }
      this.solicitud = this.hacerPagoInfo
      // console.log(solicitud.fechaPago, solicitud.monto, solicitud.frecuenciaPago, solicitud.interes, solicitud.plazoPago)
      if (solicitud.fechaPago === '' || solicitud.monto === '' || solicitud.frecuenciaPago === '' || solicitud.interes === '' || solicitud.plazoPago === '') {
        tablaBody.innerHTML = `
          <tr>
            <td colspan=6 class="text-center text-danger">
              <h3>
                ¡Faltan datos!</td>  
              </h3>
          </tr>
        `
      } else {
        this.generarPlanPago(this.stringToDate(solicitud.fechaPago), solicitud.monto, solicitud.frecuenciaPago, solicitud.interes, solicitud.plazoPago, solicitud.producto)
      }
    },

    generarPlanPago(
      fechaApertura,
      monto,
      periodoPago,
      interesAnual,
      plazo,
      producto,
    ) {
      let fechaInicial = fechaApertura
      // Calcular la tasa de interés mensual
      let tasaAnual = interesAnual / 100;
      let capitalDT = 0.0;

      // console.log("tasa anual:", tasaAnual);

      // Calcular el intervalo de tiempo entre pagos
      let duracionPago, periodo, noCuotas, interes, diasPeriodo, capital, saldo;

      saldo = monto;

      switch (this.limpiarYMinusc(periodoPago)) {
        case "mensual":
          duracionPago = 30 * 24 * 60 * 60 * 1000;
          fechaApertura = new Date(
            fechaApertura.getTime() + 30 * 24 * 60 * 60 * 1000
          );
          noCuotas = plazo;
          periodo = 12;
          diasPeriodo = 30.0;
          break;

        case "quincenal":
          duracionPago = 15 * 24 * 60 * 60 * 1000;
          fechaApertura = new Date(
            fechaApertura.getTime() + 15 * 24 * 60 * 60 * 1000
          );
          noCuotas = plazo * 2;
          periodo = 24;
          diasPeriodo = 15.0;
          break;

        case "semanal":
          duracionPago = 7 * 24 * 60 * 60 * 1000;
          fechaApertura = new Date(
            fechaApertura.getTime() + 7 * 24 * 60 * 60 * 1000
          );
          if (this.esCrediTrabajo(producto)) {
            noCuotas = plazo * 4;
          } else {
            noCuotas = plazo * (4.3333333333);
          }

          periodo = 52.1;
          diasPeriodo = 7.0;
          break;
        case "alvencimiento":
          duracionPago = 1;
          fechaApertura = new Date(
            fechaApertura.getTime() + (plazo * 30) * 24 * 60 * 60 * 1000
          );
          noCuotas = 1;
          periodo = (30 / (plazo * 30)) * 12;
          diasPeriodo = plazo * 30;
          break;
        case "trimestral":
          if (this.esAgricola(producto)) {
            duracionPago = 3;
            fechaApertura = this.sumarMeses(fechaApertura, 3)
          } else {
            duracionPago = 90 * 24 * 60 * 60 * 1000;
            fechaApertura = new Date(
              fechaApertura.getTime() + 90 * 24 * 60 * 60 * 1000
            );
          }

          noCuotas = plazo / 3;
          periodo = 4;
          diasPeriodo = 90.0;
          break;
        case "semestral":

          if (this.esAgricola(producto)) {
            duracionPago = 6;
            fechaApertura = this.sumarMeses(fechaApertura, 6)
          } else {
            duracionPago = 180 * 24 * 60 * 60 * 1000;
            fechaApertura = new Date(
              fechaApertura.getTime() + 180 * 24 * 60 * 60 * 1000
            );
          }

          noCuotas = plazo / 6;
          periodo = 2;
          diasPeriodo = 180.0;
          break;
        case "anual":

          if (this.esAgricola(producto)) {
            duracionPago = 12;
            fechaApertura = this.sumarMeses(fechaApertura, 12)
          } else {
            duracionPago = 360 * 24 * 60 * 60 * 1000;
            fechaApertura = new Date(
              fechaApertura.getTime() + 360 * 24 * 60 * 60 * 1000
            );
          }

          noCuotas = plazo / 12;
          periodo = 1;
          diasPeriodo = 360.0;
          break;

        default:
          console.log("Periodo de pago no válido");
          return;
      }

      // Generar el plan de pago
      let cuotaTotal = this.CalculaCuotaNivelada(
        monto,
        tasaAnual,
        plazo,
        periodo,
        diasPeriodo
      );
      this.planPagoToPrint = []
      const tablaBody = document.getElementById('tablaPlanPagos')
      tablaBody.innerHTML = ''
      tablaBody.innerHTML = `
        <tr>
          <th class="text-center align-middle" scope="row">0</td>
          <td class="text-center align-middle">${this.dateFormat(fechaInicial.toISOString().slice(0, 10))}</td>
          <td class="text-center align-middle"></td>
          <td class="text-center align-middle"></td>
          <td class="text-center align-middle"></td>
          <td class="text-center align-middle">${Number(saldo).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${this.moneda}</td>
          <td class="text-center align-middle">0</td>

        </tr>
      `

      this.planPagoToPrint.push({
        noCuota: 0,
        fechaPago: this.dateFormat(fechaInicial.toISOString().slice(0, 10)),
        cuotaTotal: '',
        interes: '',
        capital: '',
        saldo: saldo.toFixed(2) + " " + this.moneda,
        capitalDT: capitalDT
      })
      if (this.comprobarCrediTrabajo(this.hacerPagoInfo.producto) !== true && this.hacerPagoInfo.producto !== 'Credi Trabajo 2') {

        for (let i = 1; i <= Math.round(noCuotas); i++) {
          interes = ((saldo * tasaAnual) / 360) * diasPeriodo;
          capital = cuotaTotal - interes;
          saldo = this.convertirANoNegativo(saldo - capital);
          capitalDT += capital;
          // Imprimir la fila de la tabla
          tablaBody.innerHTML += `
          <tr>
            <th class="text-center align-middle" scope="row">${i}</th>
            <td class="text-center align-middle">${this.dateFormat(fechaApertura.toISOString().slice(0, 10))}</td>
            <td class="text-center align-middle">${Number(cuotaTotal).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${this.moneda}</td>
            <td class="text-center align-middle">${Number(interes).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${this.moneda}</td>
            <td class="text-center align-middle">${Number(capital).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${this.moneda}</td>
            <td class="text-center align-middle">${Number(saldo).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${this.moneda}</td>
            <td class="text-center align-middle">${Number(capitalDT).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${this.moneda}</td>
            </tr>
          `
          this.planPagoToPrint.push({
            noCuota: i,
            fechaPago: this.dateFormat(fechaApertura.toISOString().slice(0, 10)),
            cuotaTotal: Number(cuotaTotal).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + this.moneda,
            interes: Number(interes).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + this.moneda,
            capital: Number(capital).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + this.moneda,
            saldo: Number(saldo).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + this.moneda,
            capitalDT: Number(capitalDT).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + this.moneda
          })

          if (this.esAgricola(producto)) {
            fechaApertura = this.sumarMeses(fechaApertura, duracionPago)

          } else {
            fechaApertura = new Date(fechaApertura.getTime() + duracionPago);

          }
        }
      } else {

        if (this.creditrabajo2) {
          noCuotas = Math.round(noCuotas)
        }
        cuotaTotal = monto / noCuotas;
        for (let i = 1; i <= Math.round(noCuotas); i++) {
          interes = ((saldo * tasaAnual) / 360) * diasPeriodo;
          capital = cuotaTotal;
          saldo = this.convertirANoNegativo(saldo - capital);
          capitalDT += capital
          // Imprimir la fila de la tabla
          tablaBody.innerHTML += `
          <tr>
            <th class="text-center align-middle" scope="row">${i}</th>
            <td class="text-center align-middle">${this.dateFormat(fechaApertura.toISOString().slice(0, 10))}</td>
            <td class="text-center align-middle">${cuotaTotal.toFixed(2)} ${this.moneda}</td>
            <td class="text-center align-middle"></td>
            <td class="text-center align-middle">${capital.toFixed(2)} ${this.moneda}</td>
            <td class="text-center align-middle">${Number(saldo).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${this.moneda}</td>
            <td class="text-center align-middle">${Number(capitalDT).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${this.moneda}</td>
            </tr>
            `
          this.planPagoToPrint.push({
            noCuota: i,
            fechaPago: this.dateFormat(fechaApertura.toISOString().slice(0, 10)),
            cuotaTotal: Number(cuotaTotal).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + this.moneda,
            interes: '',
            capital: Number(capital).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + this.moneda,
            saldo: Number(saldo).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + this.moneda,
            capitalDT: Number(capitalDT).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + this.moneda
          })
          fechaApertura = new Date(fechaApertura.getTime() + duracionPago);
          // console.log(duracionPago);
          // fechaApertura = this.sumarMeses(fechaApertura, 6)


        }
      }

    },
    //Convertir string a minuscula y sin espacios
    limpiarYMinusc(string) {
      // Eliminar espacios en blanco y convertir a minúsculas
      return string.replace(/\s/g, '').toLowerCase();
    },
    //Convertir numero negativo a 0
    convertirANoNegativo(numero) {
      // Si el número es negativo, convertirlo a cero
      if (numero < 0) {
        return 0;
      } else {
        return numero;
      }
    },
    esCrediTrabajo(producto) {
      // Convertir producto a minúsculas para normalizar
      producto = producto.trim().toLowerCase();

      // Lista de valores normalizados
      const creditrabajo = [
        "creditrabajo",
        "credi trabajo",
        "credi trabajo ",
        "credi trabajo",
        "Credi Trabajo",
        "creditrabajo ",
        "creditrabajo ",
        "credi trabajo",
        "creditrabajo",
      ];

      // Verificar si el producto está en la lista de valores normalizados
      return creditrabajo.includes(producto);
    },
    handleImprimir() {




      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(this.depto ? this.depto[0] : "Data");
      worksheet.columns = [
        // { header: "ID", key: "id", width: 10 },
        { header: "Fecha Data", key: "fechaData", width: 10 },
        { header: "Número Prestamo", key: "noPrestamo", width: 20 },
        { header: "Identidad", key: "dni", width: 20 },
        { header: "Cliente", key: "nombres", width: 40 },
        { header: "Sexo", key: "sexo", width: 10 },
        { header: "Fecha Nacimiento", key: "fechaNacimiento", width: 15 },
        { header: "Es Localizable", key: "esLocalizable", width: 10 },
        { header: "Proceso", key: "estadoProceso", width: 15 },
        { header: "Comprobar Estado", key: "combrobarEstado", width: 10 },
        { header: "Monto Aprobado", key: "montoDesembolsado", width: 20 },
        { header: "Producto Financiero", key: "producto", width: 20 },
        { header: "Tasa de Interes ", key: "interes", width: 10 },
        { header: "Plazo en meses", key: "plazoPago", width: 20 },
        { header: "Numero de cuotas según Plan", key: "noCuotasPlan", width: 15 },
        { header: "Valor Cuota", key: "valorCuota", width: 15 },
        { header: "Saldo de Capital", key: "saldoCapital", width: 20 },
        { header: "Saldo de Interes", key: "saldoInteres", width: 20 },
        { header: "Saldo de Mora", key: "saldoMora", width: 20 },
        { header: "Total Saldo Adeudado", key: "totalSaldoAdeudado", width: 20 },
        { header: "Saldo Seguro Deuda", key: "seguroDeuda", width: 15 },
        { header: "Saldo Seguro Daño", key: "seguroDaño", width: 15 },
        { header: "Seguro Vehiculo/Maquinaria", key: "seguroVehiculo", width: 15 },
        { header: "Capital Pagado", key: "capitalAcumulado", width: 20 },
        { header: "Otros", key: "otros", width: 20 },
        { header: "Capital Atrasado", key: "capitalAtrasado", width: 20 },
        { header: "Interes Pagado", key: "totalInteresPagado", width: 20 },
        { header: "Fecha Otorgado", key: "fechaDesembolso", width: 15 },
        { header: "Fecha Vencimiento", key: "fechaVencimiento", width: 15 },
        { header: "Dias Mora", key: "diasMora", width: 10 },
        { header: "FormaPago", key: "frecuenciaPago", width: 20 },
        { header: "Departamento", key: "sucursal", width: 20 },
        { header: "Municipio", key: "municipio", width: 20 },
        { header: "Direccion Personal", key: "puntoReferencia", width: 50 },
        { header: "Teléfono", key: "telefono", width: 20 },
        { header: "Tipo Credito", key: "tipoCredito", width: 15 },
        { header: "Categoría Crédito", key: "categoria", width: 10 },
        { header: "Garantía", key: "garantía", width: 10 },
        { header: "Estado Crédito", key: "estado", width: 20 },
        { header: "Categoría Riesgo Cliente", key: "riesgo", width: 10 },
        { header: "EsRefinanciado", key: "riesgo", width: 10 },
        { header: "Fecha Último pago", key: "fechaUltimoPago", width: 20 },
        { header: "Fecha Ultima Cuota Pagada", key: "fechaUltimaCuotaPagada", width: 20 },
        { header: "Cuotas Mora", key: "cuotasMora", width: 20 },
        { header: "Cuotas Pagadas", key: "cuotasPagadas", width: 20 },
        { header: "Aval 1", key: "aval1", width: 30 },
        { header: "DNI Aval 1", key: "dni1", width: 20 },
        { header: "Aval 2", key: "aval2", width: 30 },
        { header: "DNI Aval 2", key: "dni2", width: 20 },
        { header: "Aval 3", key: "aval3", width: 30 },
        { header: "DNI Aval 3", key: "dni3", width: 20 },
        { header: "Aval 4", key: "aval4", width: 30 },
        { header: "DNI Aval 4", key: "dni4", width: 20 },
        { header: "Aval 5", key: "aval5", width: 30 },
        { header: "DNI Aval 5", key: "dni5", width: 20 },


      ];
      worksheet.getRow(1).font = { bold: true };


      const mappedData = this.creditos.map((credito) => ({
        // id: credito.id,
        fechaData: new Date().toISOString().slice(0, 10),
        noPrestamo: credito.noPrestamo,
        dni: credito.cliente.dni,
        nombres: credito.cliente.nombres + " " + (credito.cliente.apellidos ? credito.cliente.apellidos : ""),
        sexo: credito.infoRegistro.sexo,
        fechaNacimiento: this.dateFormat(credito.infoRegistro.fechaNacimiento),
        esLocalizable: (credito.esLocalizable?"SI":"NO"),
        estadoProceso: ((credito.estadoProceso === '' || credito.estadoProceso === 'NORMAL')?'NORMAL':credito.estadoProceso),
        combrobarEstado: ((credito.estadoProceso === '')?'Falta Comprobar Estado':"Comprobado"),
        montoDesembolsado: + credito.montoDesembolsado,
        producto: credito.solicitud.producto,
        interes: credito.solicitud.interes + "%",
        plazoPago: credito.solicitud.plazoPago + " " + "Meses",
        // noCuotasPlan: credito.noCuotas,
        noCuotasPlan: credito.totalCuotasPagar2 ,
        valorCuota: credito.valorCuota,
        saldoCapital: (credito.saldo > 0 ? credito.saldo : 0),
        // saldoInteres: "L." + (credito.capitalIntereses - credito.totalInteresPagado - credito.montoDesembolsado).toFixed(2), //Pendiente Simulador
        saldoInteres: (credito.simularPagoHoy.saldoInteres > 0 ? credito.simularPagoHoy.saldoInteres : 0), //Pendiente Simulador
        saldoMora: credito.simularPagoHoy.saldoMora, //Pendiente Simulador
        totalSaldoAdeudado: credito.saldo+(credito.simularPagoHoy.saldoInteres > 0 ? credito.simularPagoHoy.saldoInteres : 0)+credito.simularPagoHoy.saldoMora, //Pendiente Simulador
        seguroDeuda: "N/A",
        seguroDaño: "N/A",
        seguroVehiculo: "N/A",
        // capitalAcumulado: credito.capitalAcumulado,
        capitalAcumulado: (this.comprobarEstado(credito.estado)) ? credito.montoDesembolsado : credito.capitalAcumulado,
        otros: (this.comprobarEstado(credito.estado)) ? (credito.capitalAcumulado - credito.montoDesembolsado) : 0,
        // capitalAtrasado: (credito.saldo > 0 ? credito.saldo : 0),
        capitalAtrasado: (credito.simularPagoHoy.capitalDebePagar > 0 ? credito.simularPagoHoy.capitalDebePagar : 0),
        totalInteresPagado: credito.totalInteresPagado,
        fechaDesembolso: this.dateFormat(credito.fechaDesembolso),
        fechaVencimiento: this.dateFormat(credito.fechaVencimiento),
        diasMora: ((this.compareStrings(credito.estado, "cancelado")) ? 0 : credito.simularPagoHoy.diasMora),//Pendiente Simulador
        frecuenciaPago: credito.solicitud.frecuenciaPago,
        sucursal: credito.solicitud.sucursal,
        municipio: credito.cliente.municipio,
        puntoReferencia: credito.cliente.puntoReferencia,
        telefono: credito.cliente.telefono,
        tipoCredito: 'pequeño',
        categoria: '|',
        estado: this.mayusculasSinEspacios(credito.estado),
        riesgo: ((credito.esRefinanciado) ? 'SI' : 'NO'),
        fechaUltimoPago: this.dateFormat(credito.fechaUltimaPago),
        fechaUltimaCuotaPagada: this.dateFormat(credito.simularPagoHoy.fechaUltimaCuotaPagada), //Pendiente
        cuotasMora: 0, //Pendiente
        cuotasPagadas: credito.noCuotas - 1, //Pendiente
        aval1: credito.solicitud.integrantesGruposSolidario.nombre1Aval.String + " " + credito.solicitud.integrantesGruposSolidario.apellido1Aval.String,
        dni1: (credito.solicitud.integrantesGruposSolidario.int1 != 0.00) ? credito.solicitud.integrantesGruposSolidario.int1 : "",
        aval2: credito.solicitud.integrantesGruposSolidario.nombre2Aval.String + " " + credito.solicitud.integrantesGruposSolidario.apellido2Aval.String,
        dni2: (credito.solicitud.integrantesGruposSolidario.int2 != 0.00) ? credito.solicitud.integrantesGruposSolidario.int2 : "",
        aval3: credito.solicitud.integrantesGruposSolidario.nombre3Aval.String + " " + credito.solicitud.integrantesGruposSolidario.apellido3Aval.String,
        dni3: (credito.solicitud.integrantesGruposSolidario.int3 != 0.00) ? credito.solicitud.integrantesGruposSolidario.int3 : "",
        aval4: credito.solicitud.integrantesGruposSolidario.nombre4Aval.String + " " + credito.solicitud.integrantesGruposSolidario.apellido4Aval.String,
        dni4: (credito.solicitud.integrantesGruposSolidario.int4 != 0.00) ? credito.solicitud.integrantesGruposSolidario.int4 : "",
        aval5: credito.solicitud.integrantesGruposSolidario.nombre5Aval.String + " " + credito.solicitud.integrantesGruposSolidario.apellido5Aval.String,
        dni5: (credito.solicitud.integrantesGruposSolidario.int5 != 0.00) ? credito.solicitud.integrantesGruposSolidario.int5 : "",


        // actividadEconomica: credito.cliente.actividadEconomica,
        // puntoReferenciaNegocio: credito.cliente.puntoReferenciaNegocio,
        // montoDesembolsadoReal: credito.montoDesembolsadoReal,
        // noCuotas: credito.noCuotas,
        // noCuotasPagadas: credito.noCuotasPagadas,
        // totalMoraPagada: credito.totalMoraPagada,
        // totalPagado: credito.totalPagado,
        // fechaUltimaPago: credito.fechaUltimaPago,
        // ... (rest of your properties)
      }));
      worksheet.addRows(mappedData);
      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        // a.setAttribute("download", `CARTERA_${(this.sucursalesRoles[0] == 'ALL' ? "HISTORICA" : this.sucursalesRoles[0]) + "_AHDECO" + "_" + this.hoy()}.xlsx`);
        a.setAttribute("download", `CARTERA_${((this.sucursalFiltro == 'MOSTRAR TODAS') ? "COMPLETA" : (this.sucursalesRoles[0] == 'ALL' ? this.sucursalFiltro : this.sucursalesRoles[0])) + "_AHDECO" + "_" + this.estadoFiltro + "_" + this.hoy()}.xlsx`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    },
   

    getCountArchivos() {

      fetch(process.env.VUE_APP_API_URL + `/admin/solicitud/count-archivos/${this.verMasData.id}`, Security.requestOptions({}))
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            this.$emit('error', response.message);
            console.error(response.message)


          } else {
            console.log('Credito:', response)
            this.contadorArchivos = response.data.data
          }
        })
        .catch(error => {
          this.$emit('error', error)
        })
    },
    getCreditosOptimizados() {
      // if (this.creditos.length != 0) {
      //   this.handleImprimir()

      // } else {

        Swal.fire({
          icon: "info",
          title: "La cartera se está descargando",
          html: `
    <div class="text-center">
      <div class="spinner-border text-info" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
      `,
          showConfirmButton: false,
        });
        const userRoles = Security.getUserSucursal();
        // console.log('Inicio', userRoles[0]);
        this.readyExcel = false

        const payload = {
          sucursal: this.sucursalFiltro,
          estado: this.estadoFiltro,
          sucursales: userRoles[0]
        }
        console.log('payload', payload)
        // this.depto = userRoles[0]
        // this.estado = 'Activo'
        console.log('Inicio', payload);
        fetch(process.env.VUE_APP_API_URL + "/admin/credito/all", Security.requestOptions(payload))
          .then((response) => response.json())
          .then((response) => {
            if (response.error) {
              // this.$emit('error', response.message);

              console.error(response)
            } else {
              console.log('creditosOptimizados', response.data)

              this.creditosOptimizados = response.data.creditos.reverse()
              // this.creditosOptimizados = response.data.creditos
              this.ready = true;
              this.creditos = response.data.creditos.reverse()
              this.readyExcel = true;




              Swal.close()
              this.handleImprimir()


            }
          })
          .catch(error => {
            this.$emit('error', error)
          })

      // }
    },
    getCreditos() {
      const userRoles = Security.getUserSucursal();
      console.log('Inicio', userRoles[0]);

      const payload = {
        sucursal: userRoles[0],
        estado: this.estado
      }
      console.log('payload', payload)
      this.depto = userRoles[0]
      this.estado = 'Activo'
      console.log('Inicio', payload);
      fetch(process.env.VUE_APP_API_URL + "/admin/credito/all", Security.requestOptions(payload))
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            this.$emit('error', response.message);

            console.error(response.message)
          } else {
            console.log('Credito All getCredito()', response.data)

            this.creditos = response.data.creditos
            this.creditos = response.data.creditos.reverse()
            this.readyExcel = true;
            // this.creditos ? this.initializeDataTable() : null;
          }
        })
        .catch(error => {
          this.$emit('error', error)
        })
    },
    hoy() {
      const today = new Date();
      const year = today.getFullYear();
      let month = today.getMonth() + 1; // Sumar 1 porque los meses comienzan desde 0
      let day = today.getDate();

      // Agregar un cero inicial si el mes o día es menor que 10 para mantener el formato 'YYYY-MM-DD'
      if (month < 10) {
        month = `0${month}`;
      }
      if (day < 10) {
        day = `0${day}`;
      }

      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate
    },
    initializeDataTable() {
      console.log('Despues Iniciar');
      const myTable = document.querySelector("#myTable");
      if (myTable) {
        new DataTables(myTable);
      }


    },
    llamarEspejo(id) {
      console.log(id)
      Swal.fire({
        title: '¿Estás Seguro que desea Regresar a un pago atras?',
        icon: 'warning',
        text: '¡Esta Función devolvera el credito al anterior que tenía!',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!'
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('confirmado')
          fetch(process.env.VUE_APP_API_URL + `/admin/credito/devolver-credito`, Security.requestOptions({ idCredito: id }))
            .then((response) => response.json())
            .then((response) => {
              if (response.error) {
                this.$emit('error', response.message);
                console.error(response.message)
              } else {
                console.log('Credito:', response)
              }
            })
            .catch(error => {
              this.$emit('error', error)
            })
          location.reload();
        }
      })

    },
    
    infoRegistro() {
      fetch(process.env.VUE_APP_API_URL + `/admin/beneficiario-registro/${this.verMasData.cliente.dni}`, Security.requestOptions({}))
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            this.$emit('error', response.message);
            console.error(response.message)
          } else {
            console.log('Registro:', response)
            this.verMasData.infoRegistro = response.data
            console.log('Registro Ver mas data:', this.verMasData)

            // this.idCredito = response.data.id
            // this.readyVerMas = true
          }
        })
        .catch(error => {
          this.$emit('error', error)
        })
    },
    dateFormat(param) {
      // const date = new Date(param)

      // const dia = (param)?.slice(8, 10)
      // const mes = date.toLocaleDateString('es-ES', { month: 'long' })
      // const anio = date.getFullYear()
      // const dateFormated = `${dia}/${mes.slice(0, 3)}/${anio}`
      // return dateFormated
      // Fecha en formato ISO 8601
      // Fecha en formato ISO 8601
      var fechaISO = param;


      // Crear un objeto Date con la fecha proporcionada
      var fecha = new Date(fechaISO);

      // Obtener el día del mes (agregando un cero inicial si es necesario)
      var dia = ('0' + fecha.getUTCDate()).slice(-2);

      // Obtener el mes (agregando un cero inicial si es necesario)
      var mes = ('0' + (fecha.getUTCMonth() + 1)).slice(-2);

      // Obtener el año
      var anio = fecha.getUTCFullYear();

      // Formatear la fecha según tus necesidades
      var fechaFormateada = dia + '/' + mes + '/' + anio;

      // console.log(fechaFormateada); // Salida: 01/12/2023

      return fechaFormateada;

    },
    handleShowPagos(id) {
      const payload = { idCredito: id }

      fetch(process.env.VUE_APP_API_URL + "/admin/pagos/allById", Security.requestOptions(payload))
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            this.$emit('error', response.message);
            alert(response.message)
          } else {
            console.log('Solicitudes', response.data.solicitudes)
            this.solicitudes = response.data.solicitudes
            this.ready = true;
            // Llama a la función initializeDataTable() después de un retraso de 2 segundos (2000 milisegundos)
            // setTimeout(this.initializeDataTable, 20000);                    
          }
        })
        .catch(error => {
          this.$emit('error', error)
        })
    },

    handleMoveInfo(id, fechaUltimoPago) {
      this.idCredito = id

      this.fechaUltimoPago = fechaUltimoPago?.toString().slice(0, 10)
      this.fechaPagado = ''
      // this.handleSeeMore(id);
      // console.log("Fecha Ultima Pago :3", fechaUltimoPago);

      this.fechaPagado = fechaUltimoPago?.toString().slice(0, 10)
      // console.log(this.fechaPagado, ' Desde cartera')
      let payload = {
        idCredito: this.idCredito,
        fechaPagado: this.fechaPagado,
        montoPagado: 0
      };

      fetch(process.env.VUE_APP_API_URL + `/admin/credito/simular-pago`, Security.requestOptions(payload))
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            this.$emit('error', response.message);
            console.error(response.message);
          } else {
            console.log('Información del pago:', response);
            this.hacerPagoInfo = response.data;

          }
        })
        .catch(error => {
          this.$emit('error', error);
        });

      let payload2 = {
        idCredito: id
      }
      fetch(process.env.VUE_APP_API_URL + `/admin/pagos/allById`, Security.requestOptions(payload2))
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            Swal.fire({
              position: 'top-end',
              icon: "error",
              title: response.error,
              showConfirmButton: false,
              timer: 1500,
            });

          } else {
            console.log('Pagos Hecho:', response);
            this.pagos = response.data.pagos;

          }
        })
        .catch(error => {
          this.$emit('error', error);
        });


      // Check if fecha_ultima_cuota is a valid date
      // if (isNaN(new Date(fecha_ultima_cuota).getTime())) {
      //   console.error('Invalid date:', fecha_ultima_cuota);
      //   return;
      // }
      // console.log('fechaPagada', this.fechaPagado);
      // console.log('id', id);
      // console.log('montoPagado', this.montoPagado)
      // const date = new Date(fecha_ultima_cuota);

      // this.fechaUltimoPago = date.toISOString().slice(0, 10);
      // console.log(this.fechaUltimoPago);
      // this.fechaDataUltimoPago = date.toISOString().slice(0, 10);
      // let payload = {
      //   idCredito: id,
      //   fechaPagado: this.fechaPagado,
      //   montoPagado: 0
      // }
      // let payload = {
      //   idCredito: this.idCredito,
      //   fechaPagado: this.fechaPagado,
      //   montoPagado: parseFloat(this.montoPagado)
      // }
      // fetch(process.env.VUE_APP_API_URL + `/admin/credito/simular-pago`, Security.requestOptions(payload))
      //   .then((response) => response.json())
      //   .then((response) => {
      //     if (response.error) {
      //       this.$emit('error', response.message);
      //       console.error(response.message)
      //     } else {
      //       console.log('pago Informacion:', response)
      //       this.hacerPagoInfo = response.data
      //       this.fechaUltimoPago = response.data.ultimaFechaPagada

      //     }
      //   })
      //   .catch(error => {
      //     this.$emit('error', error)
      //   })
    },
    simularPagoHoy(id) {
      let payload = {
        idCredito: id,
        fechaPagado: this.hoy(),
        montoPagado: 0
      };

      fetch(process.env.VUE_APP_API_URL + `/admin/credito/simular-pago`, Security.requestOptions(payload))
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            this.$emit('error', response.message);
            console.error(response.message);
          } else {
            console.log('Información del pago:', response);
            return {
              saldoInteres: 5,
              saldoMora: 5,
              diasMora: response.data.diasMora,
              fechaUltimaCuotaPagada: 5,

            }

          }
        })
        .catch(error => {
          this.$emit('error', error);
        });


      return {
        saldoInteres: 5,
        saldoMora: 5,
        diasMora: 5,
        fechaUltimaCuotaPagada: 5,

      }
    },
    handleSubmitPago() {

      if (this.montoPagado !== '' && this.fechaPagado !== '' && this.validateInput()) {

        // this.validateInput()
        const formData = new FormData();
        formData.append('image', this.recibo);


        const payload = {
          idCredito: parseInt(this.idCredito),
          fechaPagado: this.fechaPagado,
          montoPagado: this.montoPagado,
          idUsuario: Security.getUser()[0].user.id,
          noReferenciaRecibo: String(this.noReferenciaRecibo),
          noCuenta: this.noCuenta,
          ifi: this.nombreBanco,
          recibo: "recibo.png",
        }

        // console.log(payload)
        Swal.fire({
          title: '¿Estás Seguro que desea Registrar el pago?',
          icon: 'success',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirmar!'
        }).then((result) => {
          if (result.isConfirmed) {
            console.log('confirmado')

            fetch(
              process.env.VUE_APP_API_URL +
              "/admin/credito/registro-pago",
              Security.requestOptions(payload)
            )
              .then((response) => response.json())
              .then((data) => {
                if (data.error) {
                  Swal.fire({
                    // position: 'top-end',
                    icon: "error",
                    title: data.message,
                    showConfirmButton: false,
                    timer: 1500,
                  });

                  // this.validateInput()

                } else {
                  console.log(data.data);
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "Pago Registrado Exitosamente!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.submitImage(data.slug)

                }
              });

            // this.montoPagado = ''
            // this.fechaPagado = ''

          }
        })
      } else {
        Swal.fire({
          // position: "top-end",
          icon: "error",
          title: "Completa los datos",
          showConfirmButton: false,
          timer: 1500
        });
      }


    },
    async submitImage(slug) {
      // console.log();
      const formData = new FormData();
      const newFileName = slug + ".jpg"; // Cambia esto al nuevo nombre que desees

      // Agregar el archivo con un nuevo nombre al FormData
      if (this.imagenPegada) {

        try {
          // Convertir la URL en un objeto File
          const file = await this.convertirURLaFile(this.imagenPegada, newFileName);

          // Agregar el objeto File al FormData
          formData.append('image', file, newFileName);

        } catch (error) {
          console.error('Error al convertir la imagen pegada:', error);
          // Manejar el error según sea necesario
        }

      } else {
        formData.append('image', this.recibo, newFileName);
      }

      try {
        const response = await fetch(process.env.VUE_APP_API_URL +
          "/admin/credito/obtener-imagen", Security.requestOptionsImagen(formData));

        // Manejar la respuesta del backend según sea necesario
        console.log('Respuesta del servidor:', response);
        Swal.fire({
          // position: "top-end",
          icon: "success",
          title: "Imagen de Recibo enviada exitosamente!",
          showConfirmButton: false,
          timer: 1500
        });
      } catch (error) {
        console.error('Error al enviar la imagen:', error);
        Swal.fire({
          // position: "top-end",
          icon: "error",
          title: error,
          showConfirmButton: false,
          timer: 1500
        });
      }
      router.push("/cartera");
      location.reload()
    },
    cleanData() {
      this.montoPagado = ''

    },
    validateInput() {
      // let input = document.getElementById(idInput)


      // if (!input.checkValidity()) {
      //   input.classList.add("was-validated");
      // }
      // if (input.value.value === null || input.value.value === "" || input.value.value === 0) {
      //   input.classList.add("was-validated");
      // }


      if (this.fechaPagado == '') {
        document.getElementById("fechaPagado").style.border = "2px solid red";
        return false
      }
      if (this.montoPagado == '') {
        document.getElementById("montoPago").style.border = "2px solid red";
        return false
      }
      if (this.nombreBanco == '') {
        document.getElementById("nombreBanco").style.border = "2px solid red";
        return false
      }
      if (this.noCuenta === "") {
        // document.getElementById("noCuenta").classList.add("was-validated");
        document.getElementById("noCuenta").style.border = "2px solid red";
        return false
      }
      if (this.noReferenciaRecibo === "") {
        // document.getElementById("noCuenta").classList.add("was-validated");
        document.getElementById("noReferenciaRecibo").style.border = "2px solid red";
        return false
      }
      if (this.recibo === null) {
        // document.getElementById("noCuenta").classList.add("was-validated");
        document.getElementById("formFile").style.border = "2px solid red";
        return false
      }



      return true

    },
    // Función para descargar la imagen desde la URL y convertirla en un objeto File
    async convertirURLaFile(url, nombreArchivo) {
      try {
        // Obtener la imagen desde la URL
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Error al obtener la imagen');
        }

        // Convertir la respuesta en un Blob
        const blob = await response.blob();

        // Crear un objeto File con el Blob
        const file = new File([blob], nombreArchivo, { type: blob.type });

        return file;
      } catch (error) {
        console.error('Error al convertir la URL en un objeto File:', error);
        throw error;
      }
    },
    compareStrings(str1, str2) {
      // Convertir el primer string a minúsculas y eliminar los espacios
      str1 = str1.toLowerCase().replace(/\s/g, '');
      // Convertir el segundo string a minúsculas y eliminar los espacios
      str2 = str2.toLowerCase().replace(/\s/g, '');
      // Comparar los dos strings
      if (str1 === str2) {
        return true;
      } return false
    },
    formatoNumero(numero) {
      return new Intl.NumberFormat("en-US").format(numero)
    },


    generateExcel() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Data");

      // Agrega las columnas al archivo Excel
      worksheet.columns = [
        { header: "No.", key: "No." },
        { header: "Estatus", key: "Estatus" },
        { header: "Departamento", key: "Departamento" },
        { header: "No. Prestamo", key: "No. Prestamo" },
        { header: "Rubro", key: "Rubro" },
        { header: "NOMBRE DEL CLIENTE", key: "NOMBRE DEL CLIENTE" },
        { header: "IDENTIDAD", key: "IDENTIDAD" },
        { header: "No. Tel/Cel", key: "No. Tel/Cel" },
        { header: "DIRECCION", key: "DIRECCION" },
        { header: "NEGOCIO", key: "NEGOCIO" },
        { header: "Producto", key: "Producto" },
        { header: "Fecha de Desembolso", key: "Fecha de Desembolso" },
        { header: "Fecha de Vencimiento", key: "Fecha de Vencimiento" },
        { header: "Monto Financiado", key: "Monto Financiado" },
        { header: "Frecuencia", key: "Frecuencia" },
        { header: "Periodo de gracia", key: "Periodo de gracia" },
        { header: "Valor de la Cuota", key: "Valor de la Cuota" },
        { header: "No. de cuotas", key: "No. de cuotas" },
        { header: "Capital mas intereses a pagar", key: "Capital mas intereses a pagar" },
        { header: "Plazo", key: "Plazo" },
        { header: "Tasa de Interes", key: "Tasa de Interes" },
        { header: "Interes corriente", key: "Interes corriente" },
        { header: "Interes Moratorios", key: "Interes Moratorios" },
        { header: "Capital", key: "Capital" },
        { header: "Total pagado", key: "Total pagado" },
        { header: "Fecha ultima cuota pagada", key: "Fecha ultima cuota pagada" },
        { header: "Fecha ultima de pago", key: "Fecha ultima de pago" },
        { header: "Número de cuotas pagadas", key: "Número de cuotas pagadas" },
        { header: "Saldo Capital", key: "Saldo Capital" },
        { header: "Saldo Intereses", key: "Saldo Intereses" },
        { header: "Saldo interes moratorio", key: "Saldo interes moratorio" },
        { header: "Cuotas al día", key: "Cuotas al día" },
        { header: "Capital atrasado", key: "Capital atrasado" },
        { header: "Interes atrasado", key: "Interes atrasado" },
        { header: "Calculo interes vencido", key: "Calculo interes vencido" },
        { header: "Cuotas vencidas", key: "Cuotas vencidas" },
        { header: "Dias vencidos", key: "Dias vencidos" },
        { header: "Rango 1-30 días", key: "Rango 1-30 días" },
        { header: "Monto vencido 1-30 días", key: "Monto vencido 1-30 días" },
        { header: "Rango 31-60 días", key: "Rango 31-60 días" },
        { header: "Monto vencido 31-60 días", key: "Monto vencido 31-60 días" },
        { header: "Rango 61-90 días", key: "Rango 61-90 días" },
        { header: "Monto vencido 61-90 días", key: "Monto vencido 61-90 días" },
        { header: "Rango de más 90 días", key: "Rango de más 90 días" },
        { header: "Monto vencido más 90 días", key: "Monto vencido más 90 días" },
      ];

      // Datos de prueba
      const testData = [
        {
          "No.": 1,
          Estatus: "Activo",
          Departamento: "Finanzas",
          "No. Prestamo": "P001",
          Rubro: "Préstamo personal",
          "NOMBRE DEL CLIENTE": "Juan Pérez",
          IDENTIDAD: "1234567890",
          "No. Tel/Cel": "9876543210",
          DIRECCION: "Calle Principal 123",
          NEGOCIO: "N/A",
          Producto: "Préstamo personal",
          "Fecha de Desembolso": "2023-01-15",
          "Fecha de Vencimiento": "2024-01-15",
          "Monto Financiado": 10000,
          Frecuencia: "Mensual",
          "Periodo de gracia": 0,
          "Valor de la Cuota": 1000,
          "No. de cuotas": 12,
          "Capital mas intereses a pagar": 12000,
          Plazo: 12,
          "Tasa de Interes": 0.1,
          "Interes corriente": 1000,
          "Interes Moratorios": 0,
          Capital: 11000,
          "Total pagado": 6000,
          "Fecha ultima cuota pagada": "2023-06-15",
          "Fecha ultima de pago": "2023-11-05",
          "Número de cuotas pagadas": 6,
          "Saldo Capital": 5000,
          "Saldo Intereses": 500,
          "Saldo interes moratorio": 0,
          "Cuotas al día": 6,
          "Capital atrasado": 0,
          "Interes atrasado": 0,
          "Calculo interes vencido": 0,
          "Cuotas vencidas": 0,
          "Dias vencidos": 0,
          "Rango 1-30 días": 0,
          "Monto vencido 1-30 días": 0,
          "Rango 31-60 días": 0,
          "Monto vencido 31-60 días": 0,
          "Rango 61-90 días": 0,
          "Monto vencido 61-90 días": 0,
          "Rango de más 90 días": 0,
          "Monto vencido más 90 días": 0,
        },
        {
          "No.": 2,
          Estatus: "Inactivo",
          Departamento: "Ventas",
          "No. Prestamo": "P002",
          Rubro: "Préstamo para negocio",
          "NOMBRE DEL CLIENTE": "Maria Rodriguez",
          IDENTIDAD: "0987654321",
          "No. Tel/Cel": "7890123456",
          DIRECCION: "Avenida Central 456",
          NEGOCIO: "Tienda de ropa",
          Producto: "Préstamo para negocio",
          "Fecha de Desembolso": "2023-03-10",
          "Fecha de Vencimiento": "2024-03-10",
          "Monto Financiado": 20000,
          Frecuencia: "Quincenal",
          "Periodo de gracia": 2,
          "Valor de la Cuota": 1500,
          "No. de cuotas": 10,
          "Capital mas intereses a pagar": 15000,
          Plazo: 12,
          "Tasa de Interes": 0.08,
          "Interes corriente": 800,
          "Interes Moratorios": 100,
          Capital: 14000,
          "Total pagado": 6000,
          "Fecha ultima cuota pagada": "2023-08-15",
          "Fecha ultima de pago": "2023-10-05",
          "Número de cuotas pagadas": 4,
          "Saldo Capital": 10000,
          "Saldo Intereses": 1000,
          "Saldo interes moratorio": 200,
          "Cuotas al día": 3,
          "Capital atrasado": 0,
          "Interes atrasado": 0,
          "Calculo interes vencido": 0,
          "Cuotas vencidas": 0,
          "Dias vencidos": 0,
          "Rango 1-30 días": 0,
          "Monto vencido 1-30 días": 0,
          "Rango 31-60 días": 0,
          "Monto vencido 31-60 días": 0,
          "Rango 61-90 días": 0,
          "Monto vencido 61-90 días": 0,
          "Rango de más 90 días": 0,
          "Monto vencido más 90 días": 0,
        },
      ];

      // Agrega los datos al archivo Excel
      testData.forEach((data) => {
        worksheet.addRow(data);
      });


      // Genera el archivo Excel
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "data.xlsx";
        a.click();
        URL.revokeObjectURL(url);
      });
    },
    mayusculasSinEspacios(texto) {
      return texto.toUpperCase().replace(/\s/g, '');
    },

  },

  watch: {
    nombreBanco: function (nombreBanco) {
      let numerosDeCuenta = this.bancos.find(banco => banco.nombreBanco === nombreBanco);

      // console.log(numerosDeCuenta.cuentas)
      // console.log(nombreBanco)

      // console.log(numerosDeCuenta)
      this.numerosDeCuenta = numerosDeCuenta.cuentas;
    },
    data: function (data) {
      console.log('data', data)
    },
    verMasData: function (verMasData) {
      console.log('verMasData', verMasData)
    },
    creditos: function (creditos) {
      console.log('creditos', creditos)
    },
    sucursal: function (sucursal) {
      // const payload = { 
      //   sucursal: userRoles[0], 
      //   estado: this.estado  
      // }
      console.log("sucursal", sucursal);
      // if (sucursal === 'Todos') {

      // }
    },
    esRefinanciado: function (newValue, oldValue) {
      if (this.verMasData.esRefinanciado != newValue) {
        Swal.fire({
          title: '¿Desea cambia el estado de Refinanciamiento?',
          icon: 'success',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirmar!'
        }).then((result) => {
          if (result.isConfirmed) {
            let payload = {
              idCredito: this.verMasData.id,
              esRefinanciado: this.esRefinanciado
            }

            console.log('confirmado')
            fetch(process.env.VUE_APP_API_URL + `/admin/credito/es-refinanciado`, Security.requestOptions(payload))
              .then((response) => response.json())
              .then((response) => {
                if (response.error) {
                  this.$emit('error', response.message);
                  console.error(response.message)
                } else {
                  console.log('Update', response)
                  // location.reload();
                  console.log('Se cambia', oldValue, "por", newValue);

                }
              })
              .catch(error => {
                this.$emit('error', error)
              })
          } else {
            this.esRefinanciado = oldValue
          }
        })
      }

    },

    estado: function (estado) {
      this.creditos = [];
      this.ready = false;
      const userRoles = Security.getUserSucursal();

      const payload = {
        sucursal: userRoles[0],
        estado: estado
      }

      if (this.sucursal != 'MOSTRAR TODAS') {
        payload.sucursal = this.sucursal
      }


      fetch(process.env.VUE_APP_API_URL + "/admin/credito/all", Security.requestOptions(payload))
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            this.$emit('error', response.message);

            console.error(response.message)
          } else {
            console.log('Creditos All:', response.data)

            this.creditos = response.data.creditos.reverse()
            this.ready = true;


          }
        })
        .catch(error => {
          this.$emit('error', error)
        })
    },

    fechaPagado(newValue, oldValue) {
      // console.log('Nueva fecha pagada:', newValue);
      // console.log('Antigua fecha pagada:', oldValue);

      // console.log(this.fechaUltimoPago)
      //Recordar Activar en Produccion
      // if (newValue>=this.fechaUltimoPago?.slice(0, 10) && newValue <= new Date().toISOString().slice(0, 10)) {
      if (newValue >= this.fechaUltimoPago?.slice(0, 10)) {
        let payload = {
          idCredito: this.idCredito,
          fechaPagado: newValue,
          montoPagado: 0
        };
        this.hacerPagoInfo = {}
        fetch(process.env.VUE_APP_API_URL + `/admin/credito/simular-pago`, Security.requestOptions(payload))
          .then((response) => response.json())
          .then((response) => {
            if (response.error) {
              this.$emit('error', response.message);
              console.error(response.message);
            } else {
              console.log('Información del pago:', response);
              this.hacerPagoInfo = response.data;



            }
          })
          .catch(error => {
            this.$emit('error', error);
          });


      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'La fecha no puede ser menor a la fecha del ultimo pago o mayor a la fecha actual',
        })
        this.fechaPagado = oldValue
      }
      // if (newValue?.length >= 10 && newValue < this.fechaUltimoPago?.slice(0, 10)) {
      //   this.fechaPagado = ''; // Reinicia fechaPagado si es menor que fechaUltimoPago
      // } else {
      //   let payload = {
      //     idCredito: this.idCredito,
      //     fechaPagado: newValue,
      //     montoPagado: 0
      //   };

      //   fetch(process.env.VUE_APP_API_URL + `/admin/credito/simular-pago`, Security.requestOptions(payload))
      //     .then((response) => response.json())
      //     .then((response) => {
      //       if (response.error) {
      //         this.$emit('error', response.message);
      //         console.error(response.message);
      //       } else {
      //         console.log('Información del pago:', response);
      //         this.hacerPagoInfo = response.data;
      //       }
      //     })
      //     .catch(error => {
      //       this.$emit('error', error);
      //     });
      // }

    }


  }
};
</script>

<!-- 
   <template>
    <div>
      <button @click="generateExcel">Generar Excel</button>
    </div>
  </template>
  
  <script>
  import ExcelJS from "exceljs";
  
  export default {
    methods: {
      generateExcel() {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Data");
  
        // Define las columnas y agrega los datos al archivo Excel
        const columns = [
          { header: "No.", key: "No." },
          { header: "NOMBRE DEL CLIENTE", key: "NOMBRE DEL CLIENTE" },
          { header: "No. Prestamo", key: "No. Prestamo" },
          { header: "Fecha de Desembolso", key: "Fecha de Desembolso" },
          { header: "Monto Financiado", key: "Monto Financiado" },
          { header: "Plazo", key: "Plazo" },
          { header: "Tasa de Interes", key: "Tasa de Interes" },
          { header: "Capital", key: "Capital" },
          { header: "Total pagado", key: "Total pagado" },
          { header: "Fecha ultima cuota pagada", key: "Fecha ultima cuota pagada" },
        ];
  
        worksheet.columns = columns;
  
        // Datos de prueba
        const testData = [
          {
            "No.": 1,
            "NOMBRE DEL CLIENTE": "Juan Pérez",
            "No. Prestamo": "P001",
            "Fecha de Desembolso": "2023-01-15",
            "Monto Financiado": 10000,
            Plazo: 12,
            "Tasa de Interes": 0.1,
            Capital: 11000,
            "Total pagado": 6000,
            "Fecha ultima cuota pagada": "2023-06-15",
          },
          {
            "No.": 2,
            "NOMBRE DEL CLIENTE": "Maria Rodriguez",
            "No. Prestamo": "P002",
            "Fecha de Desembolso": "2023-03-10",
            "Monto Financiado": 20000,
            Plazo: 12,
            "Tasa de Interes": 0.08,
            Capital: 14000,
            "Total pagado": 6000,
            "Fecha ultima cuota pagada": "2023-08-15",
          },
        ];
  
        // Agregar los datos de prueba
        testData.forEach((data) => {
          worksheet.addRow(data);
        });
  
        // Generar el archivo Excel
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "data.xlsx";
          a.click();
          URL.revokeObjectURL(url);
        });
      },
    },
  };
  </script>
   -->

<style>
.verificar-cartera {
  height: 20px !important;
}

.folder {
  background: #3A79EE;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  text-decoration: none;
}

.folder:hover {
  background: #2e70eb;

}

.folder i {
  font-size: 40px;
  margin: 15px;
  color: rgb(255, 255, 255);
}

.modal-footer .estado-verificado {
  width: 100%;
  text-align: center;
}

#Coordinacion,
#cordinadorSucursal {
  width: 100% !important;
}

.btn-behance {
  margin-left: 40px !important;
}

#tablaPlanPagos {
  >tr {
    >th {

      padding: 0.5rem;
    }

    >td {
      padding: 0.5rem;
    }
  }
}

#myTable_wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
}

/* Tabla Cartera */
#myTable thead tr th {
  font-size: 0.8rem;
}

#myTable tbody tr td {
  font-size: 0.8rem;
  padding-block: 5px;
}

#myTable tbody tr td button {
  font-size: 0.8rem;
}

.dataTables_paginate {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;

  >a {
    border-radius: 5px;
    border: 1px solid #f8f8f8;
    background-color: #3a79ee;
    padding: 5px 10px;
    font-size: 0.9rem;
    color: #021a47;
    text-decoration: none;
    cursor: pointer;
  }

  >span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    >a {

      border-radius: 5px;
      border: 1px solid #b9b9b9;
      padding: 5px 10px;
      font-size: 0.9rem;
      color: #021a47;
      background-color: #f0f0f0;
      text-decoration: none;
      cursor: pointer;
    }
  }

  color: #021a47;
}

#myTable_filter {
  margin-left: 10px;
  width: 200px;
  float: flex-end;

  >label input[type="search"] {
    font-size: 0.9rem;
    border-radius: 5px;
    margin-left: 10px;
    margin-top: 10px;
    height: 30px;
    border: 1px solid #021a47;

  }
}

select[name="myTable_length"] {
  border: 1px solid #021a47;
  border-radius: 5px;
  height: 30px;
  width: 100px;
  font-size: 0.9rem;
  text-align: center;
}

button {
  color: white;
}

.tb_custom {

  padding: 0;
  font-size: 0.85rem;
  width: 100%;
  margin: 0;
  border: none;

  >.trCustom_tb {
    padding: 0;
    margin: 0;
    text-align: left;

    >th {
      padding: 0;
      margin: 0;
      border: none;
    }

    >td {
      color: #233d6d;
      font-size: 0.9rem;
      padding: 0;
      margin: 0;
      border: none;
      text-align: right;
    }
  }
}

.resumenContent {
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: 0;
  padding: 0;

  >.resumenContent__item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
    margin: 0;
    padding: 0;

    >h4 {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      color: #021a47;
    }

    >p {
      font-size: 0.9rem;
      font-weight: 400;
    }


  }


}

.btn-estado {
  margin-top: 0%;
  color: white;
}

.btn-estado:hover {
  color: rgb(232, 232, 232);
}

.btn-estado:active {
  color: rgb(232, 232, 232);
}

.btn-ver-pagos {
  margin-top: 0;
  margin-left: 60%;
  margin-right: 0.2%;
  color: white;
}

.btn-ver-pagos:hover {
  color: rgb(247, 245, 255)
}

/* Media query para dispositivos móviles */
@media (max-width: 768px) {
  .btn-ver-pagos {
    margin-left: 0px;

    /* ajusta este valor según sea necesario */
  }
}

/* horizontal-scrollable>.row {
  overflow-x: auto;
  white-space: nowrap;
}

.horizontal-scrollable>.row>.col-xs-4 {
  display: inline-block;
  float: none;
} */

.horizontal-scrollable {
  overflow-x: auto;
  white-space: nowrap;
}

.horizontal-scrollable .col-xs-4 {
  display: inline-block;
  float: none;
  vertical-align: top;
  /* Añade esto para corregir problemas de alineación */
}

/* Decorations */

.col-xs-4 {
  color: white;
  font-size: 24px;
  padding-bottom: 20px;
  padding-top: 18px;
}

.col-xs-4:nth-child(2n+1) {
  background: green;
}

.col-xs-4:nth-child(2n+2) {
  background: black;
}

.btn-am {
  height: 4px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0px;
}

/* .ver-mas-right {} */

.total-pagado {
  /* height: 300px ; */
  background-color: #3a79ee;
  color: white;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  /* justify-content: center; */
  padding: 7%;

}

.pagado {
  width: 100%;
}

.pagado h4 {
  font-size: 13px;
}

.pagado p {
  font-size: 12px;
  font-weight: 300;
}

.btn-volver {
  background-color: #3a79ee;
  color: white;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 0.9rem;
  font-weight: 300;
  width: 60px;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: center;
}

.registro-pago {
  border-top: 3px solid #ff910088;
  border-right: 3px solid #4286f467;
  padding: 10px;
  padding-top: 25px;
  border-radius: 15px;
}

.modal-footer {
  padding: 0;
  padding-bottom: 5px;
  padding-right: 5px;
}

.generar-buscar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 10px; */
  width: 100%;
  /* background-color: #d5d5d5; */
  margin: 15px;
  padding: 10px;
  border-radius: 15px;
  color: white;
  width: 95%;
}

.imagen-recibo {
  width: 80%;
  margin-top: 5px
}

.importante-pagar {
  border-radius: 30px;
  border: 1px solid rgb(160, 160, 160);
  padding-block: 7px;
  padding-inline: 15px;
}

.importante-pagar:hover {

  border: 1px solid rgb(218, 189, 189);


}
</style>

<style scoped>
/* @media (min-width: 1200px){
    .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 100%;
    }
  } */
/* Estilos base para el contenedor */
.contenedor {
  position: relative;
  overflow: hidden;
  /* width: 200px; */
  /* height: 200px; */
}

/* Estilos para la imagen dentro del contenedor */
.contenedor img {

  transition: transform 0.5s ease-in-out;
}

/* Estilos para la imagen cuando se pasa el mouse sobre ella */
.contenedor:hover img {

  border-radius: 0;
  width: 80%;
  height: 80%;
  /* transform: scale(1.2); Cambia el valor según sea necesario para el nivel de zoom deseado */
}

.titulo {
  font-size: 12px;
}

.contenido-ver {
  font-size: 12px;
  margin-bottom: 0;
}

.cont-ver {
  border-radius: 12px;
  padding-block: 15px;
  margin-bottom: 20px;
  background-color: white;

  /* Aplicar drop-shadow solo para pantallas anchas */
  @media (min-width: 768px) {
    filter: drop-shadow(5px 5px 7px #0000001a);
  }
}

.cont-ver:hover {
  /* transition: cubic-bezier(0.075, 0.82, 0.165, 1); */
  /* border: 0.5px solid rgb(255, 185, 185); */

}

.cont-ver:hover hr {
  color: rgb(253, 132, 132);
  /* border: 0.5px solid rgb(255, 185, 185); */

}

@media (min-width: 992px) {

  /* .container, .container-sm, .container-md, .container-lg {
    max-width: 80%;
} */
  .horizontal-scrollable {
    max-width: 80%;
  }
}

.pagos-text {
  font-size: 12px;
  padding: 7px;
  margin-bottom: 0;
}

.pagos-text-nombre {
  font-size: 14px;
  padding: 5px;
  margin-bottom: 0;
}


.form-control {
  height: 30px;
  font-size: 12px;
}

.form-select {
  height: 30px;
  font-size: 12px;
}

.img-recibo {
  height: 40px !important;
}
</style>